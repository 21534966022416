import {useEffect} from 'react';
import {useUser} from '../contexts/UserContext';
import {pageview, setCustomDimension} from '../utils/analytics';
import {useRouter} from 'next/router';

const Analytics = () => {
  const {user} = useUser();
  const router = useRouter();

  // on first load, send pageview event to analytics
  useEffect(() => {
    pageview(router.asPath);
  }, []);

  // on route change, send pageview event to analytics
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // on user change, set the dimension isUser / isAdmin to 1
  useEffect(() => {
    if (!user || user.isGuest) {
      setCustomDimension('isUser', 1);
      return;
    }

    setCustomDimension('isAdmin', 1);
  }, [user]);

  return null;
};

export default Analytics;
