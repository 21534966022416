import React, {createContext, useCallback, useContext} from 'react';
import type {PropsWithChildren} from 'react';
import useSWR from 'swr';
import {fetchCompanyConfig} from '../utils/api';

type CompanyContextType = [Company | null, () => Promise<void>];

type RequiredCompanyContextType = [Company, () => Promise<void>];

type CompanyProviderProps = PropsWithChildren<{
  value: Company;
}>;

const CompanyContext = createContext<CompanyContextType>([
  null,
  async () => {},
]);

const CompanyProvider = ({value, children}: CompanyProviderProps) => {
  const host =
    typeof window != 'undefined' && window ? window.location.host : '';

  const {data: fetchedCompany, mutate} = useSWR(
    `/api/company/${host}`,
    () => fetchCompanyConfig(host),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnMount: false,
      fallbackData: value,
    }
  );

  const updateCompany = useCallback(
    () => mutate().then(() => undefined),
    [mutate]
  );

  const contextValue: RequiredCompanyContextType = [
    fetchedCompany!,
    updateCompany,
  ];

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

const useCompany: () => RequiredCompanyContextType = () => {
  const [company, setCompany] = useContext(CompanyContext);
  if (!company) {
    throw new Error('Company is null');
  }

  return [company, setCompany];
};

export {CompanyContext, CompanyProvider, useCompany};
