import type {AppContext} from 'next/app';
import * as API from './api';
import it from '../locale/translation.it.json';

const defaultLangs = {
  it: it,
};

export default async function localeMiddleware(
  appContext: AppContext
): Promise<void> {
  const host = appContext.ctx?.req?.headers?.host || window.location.host;
  const slug = appContext.ctx.query.store as string | undefined;

  if (slug === undefined) {
    return;
  }

  try {
    const store = await API.fetchStoreConfig(host, slug);

    const translations = await API.fetchRoomTranslations(store.rooms[0].id);

    const roomId = store.rooms[0].id;

    const translationResource = Object.entries(translations)
      .map(([lang, trans]) => ({
        [lang]: {
          ...defaultLangs[lang],
          [roomId]: trans,
        },
      }))
      .reduce((acc, t) => ({...acc, ...t}), {});

    appContext.ctx.translations = translationResource;
  } catch (e) {}
}
