import React from 'react';
import styled, {css} from 'styled-components';
import {spin} from '../Animations';
import {darken} from 'polished';

interface ButtonProps {
  type?: 'button' | 'submit';
  title: string;
  color?: string;
  textColor?: string;
  loading?: boolean;
  disabled?: boolean;
  small?: boolean;
  rounded?: boolean;
  outline?: boolean;
  full?: boolean;
  inline?: boolean;
  auto?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  icon?: React.ComponentType;
  onClick?: () => void;
}

const Button = ({
  type,
  title,
  color,
  textColor,
  loading,
  disabled,
  small,
  rounded,
  outline,
  full,
  inline,
  auto,
  secondary,
  transparent,
  icon,
  onClick,
}: ButtonProps) => {
  const IconComp = icon || React.Fragment;

  return (
    <Btn
      type={type || 'button'}
      color={color}
      textColor={textColor}
      $loading={loading}
      disabled={disabled}
      small={small}
      rounded={rounded}
      outline={outline}
      full={full}
      inline={inline}
      auto={auto}
      secondary={secondary}
      transparent={transparent}
      withIcon={!!icon}
      onClick={onClick}
    >
      {icon ? (
        <>
          <span>{title}</span>
          <em>
            <IconComp stroke={2} />
          </em>
        </>
      ) : (
        title
      )}
    </Btn>
  );
};

export default Button;

// Utils for Button
const loadingSize = 16;
const loadingBorder = 2;

const Btn = styled.button<{
  color?: string;
  textColor?: string;
  $loading?: boolean;
  disabled?: boolean;
  small?: boolean;
  rounded?: boolean;
  outline?: boolean;
  full?: boolean;
  inline?: boolean;
  auto?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  withIcon?: boolean;
}>`
  appearance: none;
  font-size: 18px;
  border: 2px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 calc(${(props) => props.theme.layout.frontendSpace} * 3);
  font-family: ${(props) => props.theme.typography.main};
  cursor: pointer;
  color: ${(props) => props.theme.colors.buttonText};
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 5px;
  white-space: nowrap;
  outline: none;
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: background-color 300ms ease, border 300ms ease;

  em {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.3);
    width: calc(${(props) => props.theme.layout.frontendSpace} * 2.5);
    height: 100%;
    margin-left: ${(props) => props.theme.layout.frontendSpace};

    svg {
      path[stroke] {
        stroke: ${(props) => props.theme.colors.buttonText};
      }
      g[stroke] {
        stroke: ${(props) => props.theme.colors.buttonText};
      }
    }
  }

  &:focus,
  &:active:active {
    border-color: ${(props) => darken(0.1, props.theme.colors.main)};
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: ${(props) => darken(0.1, props.theme.colors.main)};
    }
  }

  & + & {
    margin-left: ${(props) => props.theme.layout.frontendSpace};
  }

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.full &&
    props.withIcon &&
    css`
      em {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 17px;
      height: 30px;
      padding: 0 ${(props) => props.theme.layout.frontendSpace};
    `}

    /* auto width */
    ${(props) =>
    props.auto &&
    css`
      flex: 1 1 auto;
    `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 50px;
    `}

   ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

   ${(props) =>
    props.$loading &&
    css`
      color: transparent;
      pointer-events: none;
      cursor: default;

      &::after {
        content: '';
        display: block;
        width: ${loadingSize}px;
        height: ${loadingSize}px;
        border: solid ${loadingBorder}px rgba(255, 255, 255, 0.5);
        border-bottom-color: white;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        left: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        animation: ${spin} linear infinite 0.5s;
      }
    `}

  ${(props) =>
    props.withIcon &&
    css`
      padding-right: 0;
      padding-left: ${(props) => props.theme.layout.frontendSpace};
    `}
  ${(props) =>
    props.withIcon &&
    props.$loading &&
    css`
      em {
        opacity: 0;
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${(props) => props.theme.colors.ymGreyLighter};
      color: ${(props) => props.theme.colors.ymGreyMedium};
      border: 1px solid;
      border-color: ${(props) => props.theme.colors.ymGreyLight};

      &:focus,
      &:active:active {
        border-color: ${(props) => props.theme.colors.ymGreyMedium};
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          border-color: ${(props) => props.theme.colors.ymGreyMedium};
          background-color: ${(props) => props.theme.colors.ymGreyLight};
        }
      }
    `}
  ${(props) =>
    props.secondary &&
    props.$loading &&
    css`
      color: transparent;
      &::after {
        border-color: ${(props) => props.theme.colors.ymGreyMedium};
        border-bottom-color: white;
      }
    `}

  ${(props) =>
    props.inline &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.colors.main};
      padding: 0;
      font-size: 17px;
      height: auto;
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0px;

      &:focus,
      &:active:active {
        border: none;
        border-bottom: 1px solid;
        border-color: ${(props) => props.theme.colors.main};
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: transparent;
          color: ${(props) => darken(0.1, props.theme.colors.main)};
          border-none;
          border-bottom: 1px solid;
        }
      }
    `}
     ${(props) =>
    props.inline &&
    props.$loading &&
    css`
      color: transparent;
      &::after {
        border-color: ${(props) => props.theme.colors.main};
        border-bottom-color: white;
      }
    `};
  ${(props) =>
    props.inline &&
    props.withIcon &&
    css`
      em {
        width: auto;
        svg {
          path[stroke] {
            stroke: ${(props) => props.theme.colors.main};
          }
          g[stroke] {
            stroke: ${(props) => props.theme.colors.main};
          }
        }
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          em {
            svg {
              path[stroke] {
                stroke: ${(props) => darken(0.1, props.theme.colors.main)};
              }
              g[stroke] {
                stroke: ${(props) => darken(0.1, props.theme.colors.main)};
              }
            }
          }
        }
      }
    `}

  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.colors.main};
      border: 2px solid;
      border-color: ${(props) => props.theme.colors.main};

      @media (hover: hover) {
        &:hover:not(:disabled) {
          border-color: ${(props) => darken(0.1, props.theme.colors.main)};
          background-color: ${(props) => darken(0.1, props.theme.colors.main)};
          color: ${(props) => props.theme.colors.buttonText};
        }
      }
    `}
    ${(props) =>
    props.outline &&
    props.$loading &&
    css`
      &::after {
        border-color: ${(props) => props.theme.colors.main};
        border-bottom-color: white;
      }
    `}
    ${(props) =>
    props.outline &&
    props.withIcon &&
    css`
      em {
        svg {
          path[stroke] {
            stroke: ${(props) => props.theme.colors.main};
          }
          g[stroke] {
            stroke: ${(props) => props.theme.colors.main};
          }
        }
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          em {
            svg {
              path[stroke] {
                stroke: ${(props) => props.theme.colors.buttonText};
              }
              g[stroke] {
                stroke: ${(props) => props.theme.colors.buttonText};
              }
            }
          }
        }
      }
    `}

    ${(props) =>
    props.transparent &&
    css`
      background-color: rgba(255, 255, 255, 0.3);

      &:focus,
      &:active:active {
        border-color: #fff;
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: rgba(255, 255, 255, 0.5);
          border-color: transparent;
        }
      }
    `}

    ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      color: ${props.textColor || `#fff`};

      &:focus,
      &:active:active {
        border-color: ${darken(0.1, props.color)};
      }

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: ${darken(0.1, props.color)};
          border-color: transparent;
        }
      }
    `}
`;
