import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';

export const IconHeadphones = (props: IconProps) => {
  const theme = useContext(ThemeContext);
  const color = props.fill || theme.colors.main;
  const size = props.size || 70;
  return (
    <svg
      id="Headphones_Customer_support"
      data-name="Headphones, Customer, support"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 73.719 73.719"
    >
      <g
        id="Raggruppa_796"
        data-name="Raggruppa 796"
        transform="translate(9.215 9.215)"
      >
        <path
          id="Tracciato_430"
          data-name="Tracciato 430"
          d="M23.143,31.5H20.072A3.073,3.073,0,0,1,17,28.43V13.072A3.073,3.073,0,0,1,20.072,10h3.072a6.142,6.142,0,0,1,6.143,6.143v9.215A6.142,6.142,0,0,1,23.143,31.5Z"
          transform="translate(26.003 11.501)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
        />
        <path
          id="Tracciato_431"
          data-name="Tracciato 431"
          d="M12.215,31.5H9.143A6.142,6.142,0,0,1,3,25.358V16.143A6.142,6.142,0,0,1,9.143,10h3.072a3.073,3.073,0,0,1,3.072,3.072V28.43A3.073,3.073,0,0,1,12.215,31.5Z"
          transform="translate(-3 11.501)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
        />
        <path
          id="Tracciato_432"
          data-name="Tracciato 432"
          d="M45.431,24.5V22.966A19.965,19.965,0,0,0,25.466,3h0A19.965,19.965,0,0,0,5.5,22.966V24.5"
          transform="translate(2.179 -3)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
        />
        <path
          id="Tracciato_433"
          data-name="Tracciato 433"
          d="M17.8,26.429h-3.84a3.841,3.841,0,0,1-3.84-3.84h0a3.841,3.841,0,0,1,3.84-3.84H17.8a3.841,3.841,0,0,1,3.84,3.84h0A3.841,3.841,0,0,1,17.8,26.429Z"
          transform="translate(11.76 29.628)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
        />
        <path
          id="Tracciato_434"
          data-name="Tracciato 434"
          d="M13.875,26.215H20.4a6.142,6.142,0,0,0,6.143-6.143V17"
          transform="translate(19.529 26.003)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
        />
      </g>
      <path
        id="Tracciato_435"
        data-name="Tracciato 435"
        d="M0,0H73.719V73.719H0Z"
        fill="none"
      />
    </svg>
  );
};

export const IconCalendar = (props: IconProps) => {
  const theme = useContext(ThemeContext);
  const color = props.fill || theme.colors.main;
  const size = props.size || 70;
  return (
    <svg
      id="Calendar_Schedule"
      data-name="Calendar,Schedule"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 73.719 73.719"
    >
      <path
        id="Tracciato_420"
        data-name="Tracciato 420"
        d="M0,0H73.719V73.719H0Z"
        fill="none"
      />
      <path
        id="Tracciato_421"
        data-name="Tracciato 421"
        d="M16,2V14.287"
        transform="translate(33.146 4.143)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_422"
        data-name="Tracciato 422"
        d="M8,2V14.287"
        transform="translate(16.573 4.143)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_423"
        data-name="Tracciato 423"
        d="M3,9H58.289"
        transform="translate(6.215 18.645)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_424"
        data-name="Tracciato 424"
        d="M52.146,4h-43A6.142,6.142,0,0,0,3,10.143V50.075a6.142,6.142,0,0,0,6.143,6.143h43a6.142,6.142,0,0,0,6.143-6.143V10.143A6.142,6.142,0,0,0,52.146,4Z"
        transform="translate(6.215 8.287)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_425"
        data-name="Tracciato 425"
        d="M7.529,12.729A.768.768,0,1,0,8.3,13.5a.764.764,0,0,0-.771-.768"
        transform="translate(14.013 26.37)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_426"
        data-name="Tracciato 426"
        d="M12.529,12.729a.768.768,0,1,0,.771.768.764.764,0,0,0-.771-.768"
        transform="translate(24.371 26.37)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_427"
        data-name="Tracciato 427"
        d="M17.529,12.729a.768.768,0,1,0,.771.768.764.764,0,0,0-.771-.768"
        transform="translate(34.729 26.37)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_428"
        data-name="Tracciato 428"
        d="M7.529,16.729A.768.768,0,1,0,8.3,17.5a.764.764,0,0,0-.771-.768"
        transform="translate(14.013 34.656)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
      <path
        id="Tracciato_429"
        data-name="Tracciato 429"
        d="M12.529,16.729a.768.768,0,1,0,.771.768.764.764,0,0,0-.771-.768"
        transform="translate(24.371 34.656)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
    </svg>
  );
};

export const IconEnter = (props: IconProps) => {
  const theme = useContext(ThemeContext);
  const color = props.fill || theme.colors.main;
  const size = props.size || 32;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
    >
      <g
        id="Login_Enter"
        data-name="Login, Enter"
        transform="translate(36 36) rotate(180)"
      >
        <path
          id="Tracciato_416"
          data-name="Tracciato 416"
          d="M0,0H36V36H0Z"
          fill="none"
        />
        <path
          id="Tracciato_417"
          data-name="Tracciato 417"
          d="M3,12H21"
          transform="translate(1.5 6)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          // strokeWidth={stroke}
        />
        <path
          id="Tracciato_418"
          data-name="Tracciato 418"
          d="M5.344,25.5A13.476,13.476,0,1,0,15.328,3,13.372,13.372,0,0,0,5.344,7.5"
          transform="translate(2.672 1.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          // strokeWidth={stroke}
        />
        <path
          id="Tracciato_419"
          data-name="Tracciato 419"
          d="M7.5,9,3,13.5,7.5,18"
          transform="translate(1.5 4.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          // strokeWidth={stroke}
        />
      </g>
    </svg>
  );
};

export const IconMic = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,15v0c-1.657,0 -3,-1.343 -3,-3v-6c0,-1.657 1.343,-3 3,-3v0c1.657,0 3,1.343 3,3v6c0,1.657 -1.343,3 -3,3Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18,10v2c0,3.314 -2.686,6 -6,6v0c-3.314,0 -6,-2.686 -6,-6v-2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,18v3"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.82,21h8.36"
        ></path>
      </g>
    </svg>
  );
};

export const IconMicOff = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M12,19v3"></path>
        <path d="M9,22h6"></path>
        <path d="M8.36,4.36c0.627,-1.389 2.017,-2.36 3.64,-2.36c2.209,0 4,1.791 4,4v6"></path>
        <path d="M4.25,4.25l15.5,15.5"></path>
        <path d="M8,8v4c0,2.209 1.791,4 4,4c1.105,0 2.105,-0.448 2.828,-1.172"></path>
        <path d="M19.1,11v0.9c0,0.947 -0.189,1.848 -0.525,2.674"></path>
        <path d="M4.9,11v0.9c0,3.921 3.179,7.1 7.1,7.1c1.935,0 3.687,-0.776 4.968,-2.032"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};

export const IconCam = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M13.125,17.75h-7.875c-1.243,0 -2.25,-1.007 -2.25,-2.25v-7c0,-1.243 1.007,-2.25 2.25,-2.25h7.875c1.243,0 2.25,1.007 2.25,2.25v7c0,1.243 -1.007,2.25 -2.25,2.25Z"></path>
        <path d="M15.375,13.097l3.795,3.054c0.736,0.593 1.83,0.069 1.83,-0.876v-6.55c0,-0.945 -1.094,-1.469 -1.83,-0.876l-3.795,3.054"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconCamOff = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M3.24,3.5l11.9,17"></path>
        <path d="M13.212,17.75h-7.962c-1.243,0 -2.25,-1.007 -2.25,-2.25v-7"></path>
        <path d="M5.162,6.25h7.963c1.243,0 2.25,1.007 2.25,2.25v7"></path>
        <path d="M15.375,13.097l3.795,3.054c0.736,0.593 1.83,0.069 1.83,-0.876v-6.55c0,-0.945 -1.094,-1.469 -1.83,-0.876l-3.795,3.054"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconSettings = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M18,5h3"></path>
        <path d="M3,5h11"></path>
        <path d="M10,12h11"></path>
        <path d="M3,12h3"></path>
        <path d="M18,19h3"></path>
        <path d="M3,19h11"></path>
        <path d="M17.4142,3.58579c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,-4.44089e-16"></path>
        <path d="M9.41421,10.5858c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 -8.88178e-16,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,0"></path>
        <path d="M17.4142,17.5858c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,0"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconDashboard = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,4.001v2.439"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21,13h-2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,13h2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.36,6.64l-4.956,4.956"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.981,11c1.105,0 2,0.895 2,2c0,1.105 -0.895,2 -2,2c-1.105,0 -2,-0.895 -2,-2c0,-1.105 0.895,-2 2,-2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M5.64,6.64l2.12,2.12"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.24,17.24l2.12,2.12l0.004,0.004c3.515,-3.515 3.515,-9.213 0,-12.728c-3.515,-3.515 -9.213,-3.515 -12.728,0c-3.515,3.515 -3.515,9.213 0,12.728l0.004,-0.004l2,-2"
        ></path>
      </g>
    </svg>
  );
};

export const IconRooms = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.444,22h-6.333c-1.166,0 -2.111,-0.945 -2.111,-2.111v-10.556c0,-1.166 0.945,-2.111 2.111,-2.111h6.333c1.166,0 2.111,0.945 2.111,2.111v10.556c0.001,1.166 -0.945,2.111 -2.111,2.111Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.752,7.222l0.979,-3.657c0.302,-1.126 1.46,-1.795 2.586,-1.493l6.117,1.639c1.126,0.302 1.795,1.459 1.493,2.586l-2.732,10.196c-0.302,1.126 -1.459,1.795 -2.585,1.493l-2.055,-0.55"
        ></path>
      </g>
    </svg>
  );
};

export const IconReservations = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M16,2v4"></path>
        <path d="M8,2v4"></path>
        <path d="M3,9h18"></path>
        <path d="M19,4h-14c-1.105,0 -2,0.895 -2,2v13c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-13c0,-1.105 -0.895,-2 -2,-2Z"></path>
        <path d="M7,13h10"></path>
        <path d="M7,17h5"></path>
        <path d="M16,21v-3c0,-0.552 0.448,-1 1,-1h4"></path>
      </g>
      <path fill="none" fillRule="evenodd" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconBack = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      id="Backward_Rearward_Back.1"
      data-name="Backward, Rearward, Back.1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g id="Raggruppa_641" data-name="Raggruppa 641">
        <path
          id="Tracciato_238"
          data-name="Tracciato 238"
          d="M10,13,7.5,10.5,10,8"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
        />
        <path
          id="Tracciato_239"
          data-name="Tracciato 239"
          d="M11.5,16h1.25a2.75,2.75,0,0,0,2.75-2.75h0a2.75,2.75,0,0,0-2.75-2.75H7.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
        />
      </g>
      <path
        id="Tracciato_240"
        data-name="Tracciato 240"
        d="M0,0H24V24H0Z"
        fill="none"
      />
    </svg>
  );
};

export const IconPayment = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M17,21c-2.761,0 -5,-2.238 -5,-4.999c0,-2.705 2.3,-5.003 5.005,-5.001c2.759,0.002 4.995,2.24 4.995,5c0,2.761 -2.238,5 -5,5"></path>
        <path d="M2,6.85h19"></path>
        <path d="M21,13v-8c0,-1.105 -0.895,-2 -2,-2h-15c-1.105,0 -2,0.895 -2,2v9c0,1.105 0.895,2 2,2h8"></path>
        <path d="M19.222,14.889l-2.778,2.778l-1.666,-1.667"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconHelp = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3333"
          d="M20,20v-14.222c0,-0.982 -0.796,-1.778 -1.778,-1.778h-12.444c-0.982,0 -1.778,0.796 -1.778,1.778v10.667c0,0.982 0.796,1.778 1.778,1.778h10.889l3.333,1.777Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.998,14.791c-0.096,0 -0.174,0.078 -0.173,0.174c0,0.096 0.078,0.174 0.174,0.174c0.096,0 0.174,-0.078 0.174,-0.174c0.001,-0.096 -0.077,-0.174 -0.175,-0.174"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.999,12.076v-0.24c0,-0.787 0.486,-1.213 0.974,-1.54c0.476,-0.32 0.953,-0.738 0.953,-1.509c0,-1.064 -0.862,-1.926 -1.926,-1.926c-1.064,0 -1.927,0.86 -1.927,1.924"
        ></path>
      </g>
    </svg>
  );
};

export const IconPrivacy = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M20,20.5h-2c-0.552,0 -1,-0.448 -1,-1v-2c0,-0.552 0.448,-1 1,-1h2c0.552,0 1,0.448 1,1v2c0,0.552 -0.448,1 -1,1Z"></path>
        <path d="M12,9.19v7.31"></path>
        <path d="M13,20.5h-2c-0.552,0 -1,-0.448 -1,-1v-2c0,-0.552 0.448,-1 1,-1h2c0.552,0 1,0.448 1,1v2c0,0.552 -0.448,1 -1,1Z"></path>
        <path d="M6,20.5h-2c-0.552,0 -1,-0.448 -1,-1v-2c0,-0.552 0.448,-1 1,-1h2c0.552,0 1,0.448 1,1v2c0,0.552 -0.448,1 -1,1Z"></path>
        <path d="M16.155,9.19h-8.31c-1.571,0 -2.845,-1.274 -2.845,-2.845v0c0,-1.571 1.274,-2.845 2.845,-2.845h8.31c1.571,0 2.845,1.274 2.845,2.845v0c0,1.571 -1.274,2.845 -2.845,2.845Z"></path>
        <path d="M5,16.5v-2c0,-1.105 0.895,-2 2,-2h10c1.105,0 2,0.895 2,2v2"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};

export const IconExpand = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M13.5,16l4,-4l-4,-4"></path>
        <path d="M6.5,17l5,-5l-5,-5"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconChat = (props: IconProps) => {
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.151,16.396c-0.73,-1.3 -1.151,-2.798 -1.151,-4.396c0,-4.971 4.029,-9 9,-9c4.971,0 9,4.029 9,9c0,4.971 -4.029,9 -9,9c-1.598,0 -3.096,-0.421 -4.396,-1.151l-4.604,1.151l1.151,-4.604Z"
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      ></path>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};

export const IconEndCall = (props: IconProps) => {
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M19,4.72l-15,15.01"></path>
        <path d="M10.361,17.364c1.979,1.511 4.102,2.477 6.083,2.852c1.06,0.2 2.129,-0.109 2.87,-0.85l0.651,-0.651c0.781,-0.781 0.781,-2.047 0,-2.829l-1.173,-1.173c-0.586,-0.586 -1.536,-0.586 -2.121,0l-0.903,0.904c-0.217,0.217 -0.546,0.29 -0.827,0.166c-0.65,-0.287 -1.296,-0.647 -1.923,-1.074"></path>
        <path d="M10.868,12.856c-1.034,-1.096 -1.836,-2.299 -2.375,-3.521c-0.123,-0.28 -0.051,-0.609 0.166,-0.826l0.819,-0.819c0.671,-0.671 0.671,-1.62 0.085,-2.206l-1.173,-1.174c-0.781,-0.781 -2.047,-0.781 -2.828,0l-0.652,0.652c-0.741,0.741 -1.05,1.81 -0.85,2.87c0.475,2.514 1.907,5.253 4.195,7.639"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconUpload = (props: IconProps) => {
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,19v-7"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.833,14l2.167,-2.167l2.167,2.167"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16,19h2.56c1.928,0 3.5,-1.572 3.5,-3.5c0,-1.928 -1.572,-3.5 -3.5,-3.5h-0.435v-1c0,-3.31 -2.69,-6 -6,-6c-2.977,0 -5.445,2.178 -5.913,5.023c-2.377,0.121 -4.272,2.07 -4.272,4.477c4.44089e-16,2.485 2.015,4.5 4.5,4.5h1.56"
        ></path>
      </g>
    </svg>
  );
};

export const IconGroup = (props: IconProps) => {
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M20.7925,9.52352c0.790031,0.790031 0.790031,2.07092 0,2.86095c-0.790031,0.790031 -2.07092,0.790031 -2.86095,1.77636e-15c-0.790031,-0.790031 -0.790031,-2.07092 0,-2.86095c0.790031,-0.790031 2.07092,-0.790031 2.86095,-1.77636e-15"></path>
        <path d="M14.2026,5.91236c1.21648,1.21648 1.21648,3.18879 0,4.40528c-1.21648,1.21648 -3.18879,1.21648 -4.40528,0c-1.21648,-1.21648 -1.21648,-3.18879 0,-4.40528c1.21648,-1.21648 3.18879,-1.21648 4.40528,0"></path>
        <path d="M6.06848,9.52352c0.790031,0.790031 0.790031,2.07092 0,2.86095c-0.790031,0.790031 -2.07092,0.790031 -2.86095,1.77636e-15c-0.790031,-0.790031 -0.790031,-2.07092 0,-2.86095c0.790031,-0.790031 2.07092,-0.790031 2.86095,-1.77636e-15"></path>
        <path d="M23,19v-1.096c0,-1.381 -1.119,-2.5 -2.5,-2.5h-0.801"></path>
        <path d="M1,19v-1.096c0,-1.381 1.119,-2.5 2.5,-2.5h0.801"></path>
        <path d="M17.339,19v-1.601c0,-1.933 -1.567,-3.5 -3.5,-3.5h-3.679c-1.933,0 -3.5,1.567 -3.5,3.5v1.601"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconScreen = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19.632,16.833h-15.264c-1.105,0 -2,-0.895 -2,-2v-9.771c0,-1.105 0.895,-2 2,-2h15.263c1.105,0 2,0.895 2,2v9.772c0.001,1.104 -0.895,1.999 -1.999,1.999Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4.985,21c3.874,-1.333 10.156,-1.333 14.031,0"
        ></path>
      </g>
    </svg>
  );
};

export const IconSend = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M8.754,12.149l1.771,7.969c0.221,0.993 1.54,1.207 2.063,0.335l8.249,-13.749c0.451,-0.75 -0.089,-1.704 -0.964,-1.704h-15.551c-1.003,0 -1.505,1.212 -0.796,1.921l5.228,5.228Z"></path>
        <path d="M20.84,5.56l-12.09,6.59"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconNotifications = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M9.708,18.344v0.365c0,1.265 1.026,2.291 2.292,2.291v0c1.266,0 2.292,-1.026 2.292,-2.292v-0.365"></path>
        <path d="M13.833,5.751v-0.918c0,-1.012 -0.82,-1.833 -1.833,-1.833v0c-1.013,0 -1.833,0.821 -1.833,1.833v0.918"></path>
        <path d="M6.563,10.188v0c0,-2.503 2.029,-4.531 4.531,-4.531h1.813c2.503,0 4.531,2.029 4.531,4.531v0v2.797c0,0.53 0.211,1.039 0.586,1.414l0.641,0.641c0.375,0.375 0.586,0.884 0.586,1.414v0c0,1.044 -0.846,1.89 -1.89,1.89h-10.721c-1.044,0 -1.89,-0.846 -1.89,-1.89v0c0,-0.53 0.211,-1.039 0.586,-1.414l0.641,-0.641c0.375,-0.375 0.586,-0.884 0.586,-1.414l-8.88178e-16,-2.797Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconSpeaking = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g id="icon-speaking" transform="translate(-16917 -2355)">
        <rect
          id="Rettangolo_464"
          data-name="Rettangolo 464"
          width="24"
          height="24"
          transform="translate(16917 2355)"
          fill="rgba(255,255,255,0)"
        />
        <g
          id="Raggruppa_816"
          data-name="Raggruppa 816"
          transform="translate(36 3)"
        >
          <circle
            id="Ellisse_77"
            data-name="Ellisse 77"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(16884 2363)"
            fill={color}
          />
          <circle
            id="Ellisse_78"
            data-name="Ellisse 78"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(16891 2366)"
            fill={color}
          />
          <circle
            id="Ellisse_79"
            data-name="Ellisse 79"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(16891 2358)"
            fill={color}
          />
          <circle
            id="Ellisse_80"
            data-name="Ellisse 80"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(16898 2363)"
            fill={color}
          />
          <rect
            id="Rettangolo_463"
            data-name="Rettangolo 463"
            width="5"
            height="7"
            transform="translate(16891 2361)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export const IconPin = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M5 20l4.32-4.32" />
        <path d="M11.33 9.5l2.37-2.38 -.01 0c.25-.26.38-.61.34-.97l-.18-1.52 -.01-.01c-.04-.33.19-.62.51-.66 .17-.03.35.04.48.16l5.94 5.94 0 0c.23.22.23.6 0 .83 -.13.12-.31.19-.49.16l-1.52-.18 0 0c-.36-.04-.72.09-.97.34l-2.38 2.37 -.01 0c-.23.21-.35.51-.35.83v4.16l-.01 0c-.01.65-.53 1.17-1.18 1.17 -.32-.01-.61-.13-.83-.35L5.4 11.756l0 0c-.46-.47-.46-1.21 0-1.67 .22-.22.51-.35.82-.35h4.16l-.01 0c.31-.01.6-.13.82-.35Z" />
      </g>
      <path fill="none" d="M0 0h24v24H0Z" />
    </svg>
  );
};

export const IconPinOff = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <line
        x1="20"
        y1="19"
        x2="5.5"
        y2="4.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={stroke}
      />
      <line
        x1="8.5"
        y1="15.5"
        x2="4"
        y2="20"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={stroke}
      />
      <path
        d="M9.46106,8.46106l-4.48,1.12a1,1,0,0,0-.46454,1.67731l8.22516,8.22516a1,1,0,0,0,1.67724-.46454l1.12-4.4801"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={stroke}
      />
      <path
        d="M17.46136,12.46136l1.648-1.09869a2,2,0,0,0,.30481-3.07831L15.71558,4.58588a2,2,0,0,0-3.07831.30481L11.53864,6.53864"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={stroke}
      />
      <path d="M0,0V24H24V0Z" fill="none" />
    </svg>
  );
};

export const IconCheck = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M19.561,7.444l-7.556,7.556l-3.777,-3.778"></path>
        <path d="M21,12c0,4.971 -4.029,9 -9,9c-4.971,0 -9,-4.029 -9,-9c0,-4.971 4.029,-9 9,-9c1.453,0 2.821,0.353 4.036,0.964"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconDownload = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M18.414,6.414l-2.828,-2.828c-0.375,-0.375 -0.884,-0.586 -1.414,-0.586h-7.172c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h10c1.105,0 2,-0.895 2,-2v-11.172c0,-0.53 -0.211,-1.039 -0.586,-1.414Z"></path>
        <path d="M11.09,17l-2.03,-2.03"></path>
        <path d="M13,15.09l-1.91,1.91"></path>
        <path d="M11.09,17v-6"></path>
        <path d="M19,8h-4c-0.552,0 -1,-0.448 -1,-1v-4"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconUser = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path d="M0,0h24v24h-24Z" id="a"></path>
      </defs>
      <g fill="none">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.8171,4.16689c1.55585,1.55585 1.55585,4.07838 0,5.63423c-1.55585,1.55585 -4.07838,1.55585 -5.63423,0c-1.55585,-1.55585 -1.55585,-4.07838 -1.77636e-15,-5.63423c1.55585,-1.55585 4.07838,-1.55585 5.63423,-8.88178e-16"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,14.008c4.554,0 9,1.967 9,4.992v1c0,0.552 -0.448,1 -1,1h-16c-0.552,0 -1,-0.448 -1,-1v-1c0,-3.026 4.446,-4.992 9,-4.992"
        ></path>
      </g>
    </svg>
  );
};

export const IconFullscreen = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M7.5,3h-2.25c-1.243,0 -2.25,1.007 -2.25,2.25v2.25"></path>
        <path d="M3,16.5v2.25c0,1.243 1.007,2.25 2.25,2.25h2.25"></path>
        <path d="M16.5,21h2.25c1.243,0 2.25,-1.007 2.25,-2.25v-2.25"></path>
        <path d="M21,7.5v-2.25c0,-1.243 -1.007,-2.25 -2.25,-2.25h-2.25"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconFullscreenOff = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.5,3h-2.25c-1.243,0 -2.25,1.007 -2.25,2.25v2.25"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,16.5v2.25c0,1.243 1.007,2.25 2.25,2.25h2.25"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.5,21h2.25c1.243,0 2.25,-1.007 2.25,-2.25v-2.25"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21,7.5v-2.25c0,-1.243 -1.007,-2.25 -2.25,-2.25h-2.25"
        ></path>
        <path
          stroke={color}
          strokeWidth={stroke}
          d="M11.999,15.502c-1.934,0 -3.501,-1.568 -3.501,-3.502c0,-1.894 1.611,-3.503 3.504,-3.502c1.933,0.003 3.499,1.57 3.499,3.502c0.001,1.934 -1.566,3.502 -3.502,3.502"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.477,9.526l-4.952,4.951"
        ></path>
      </g>
    </svg>
  );
};

export const IconPip = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M11,21h-6c-1.105,0 -2,-0.895 -2,-2v-6c0,-1.105 0.895,-2 2,-2h6c1.105,0 2,0.895 2,2v6c0,1.105 -0.895,2 -2,2Z"></path>
        <path d="M19,16h-9c-1.105,0 -2,-0.895 -2,-2v-9c0,-1.105 0.895,-2 2,-2h9c1.105,0 2,0.895 2,2v9c0,1.105 -0.895,2 -2,2Z"></path>
        <path d="M15,9l3,-3"></path>
        <path d="M15,6h3v3"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
export const IconPipOff = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M10,21h-5c-1.105,0 -2,-0.895 -2,-2v-5c0,-1.105 0.895,-2 2,-2h5c1.105,0 2,0.895 2,2v5c0,1.105 -0.895,2 -2,2Z"></path>
        <path d="M13,7h4v4"></path>
        <path d="M11.41,12.59l5.59,-5.59"></path>
        <path d="M16,21h3c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2h-14c-1.105,0 -2,0.895 -2,2v3"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconRecord = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M9.91421,10.5858c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 -8.88178e-16,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,0"></path>
        <path d="M16.9142,10.5858c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,0"></path>
        <path d="M8.5,14h7"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
      <path
        d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z"
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export const IconHand = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path d="M0,0h24v24h-24Z" id="a"></path>
      </defs>
      <g fill="none">
        <use transform="matrix(-1,-4.49064e-11,4.49064e-11,-1,24,24)"></use>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M10,12v-8.5c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5v8.5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M10,5.5c0,-0.828 -0.672,-1.5 -1.5,-1.5v0c-0.828,0 -1.5,0.672 -1.5,1.5v6.5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M13,5.5c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5v5.5v3l1.901,-1.901c0.607,-0.607 1.591,-0.607 2.198,0v0c0.524,0.524 0.605,1.345 0.194,1.961l-3.512,5.268c-1.113,1.669 -2.986,2.672 -4.992,2.672h-1.789c-3.314,0 -6,-2.686 -6,-6v-5v-3.5c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5"
        ></path>
        <use transform="matrix(-1,-4.49064e-11,4.49064e-11,-1,24,24)"></use>
      </g>
    </svg>
  );
};

export const IconMenu = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-3022 -2699)">
        <rect
          width={size}
          height={size}
          transform="translate(3022 2699)"
          fill="none"
        />
        <circle
          cx="2"
          cy="2"
          r="2"
          transform="translate(3032 2702)"
          fill={color}
        />
        <circle
          cx="2"
          cy="2"
          r="2"
          transform="translate(3032 2709)"
          fill={color}
        />
        <circle
          cx="2"
          cy="2"
          r="2"
          transform="translate(3032 2716)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconEndRoom = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M7.61,16.61l4.39,4.39l4.39,-4.39"></path>
        <path d="M12,21v-9"></path>
        <path d="M18.434,12h1.566c1.105,0 2,-0.895 2,-2v-1.014c0,-1.029 -0.528,-1.986 -1.402,-2.583c-2.157,-1.471 -5.197,-2.403 -8.598,-2.403c-3.401,0 -6.441,0.932 -8.598,2.404c-0.874,0.597 -1.402,1.554 -1.402,2.582v1.014c0,1.105 0.895,2 2,2h1.566c0.828,0 1.5,-0.672 1.5,-1.5v-1.363c0,-0.307 0.181,-0.591 0.467,-0.702c1.326,-0.514 2.846,-0.81 4.467,-0.81c1.621,0 3.141,0.296 4.467,0.81c0.286,0.111 0.467,0.395 0.467,0.702v1.363c3.55271e-15,0.828 0.672,1.5 1.5,1.5Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconRoomEnter = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,12h12"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M5.344,18c1.643,1.83 4.002,3 6.656,3c4.971,0 9,-4.03 9,-9c0,-4.971 -4.029,-9 -9,-9c-2.654,0 -5.013,1.17 -6.656,3"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M6,9l-3,3l3,3"
        ></path>
      </g>
    </svg>
  );
};

export const IconArrow = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21,7.5l-9,9l-9,-9"
        ></path>
      </g>
    </svg>
  );
};

export const IconEdit = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M20.707,4.707l-1.414,-1.414c-0.391,-0.391 -1.024,-0.391 -1.414,0l-10.586,10.586c-0.188,0.187 -0.293,0.442 -0.293,0.707v2.414h2.414c0.265,0 0.52,-0.105 0.707,-0.293l10.586,-10.586c0.391,-0.39 0.391,-1.023 0,-1.414Z"></path>
        <path d="M18.91,7.91l-2.82,-2.82"></path>
        <path d="M21,11v8c0,1.105 -0.895,2 -2,2h-14c-1.105,0 -2,-0.895 -2,-2v-14c0,-1.105 0.895,-2 2,-2h8"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};

export const IconDelete = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.138,21h-8.28c-1.047,0 -1.917,-0.808 -1.995,-1.853l-0.897,-12.147h14.034l-0.867,12.142c-0.075,1.047 -0.946,1.858 -1.995,1.858Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,11v6"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4,7h16"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M17,7l-1.013,-2.702c-0.293,-0.781 -1.039,-1.298 -1.873,-1.298h-4.228c-0.834,0 -1.58,0.517 -1.873,1.298l-1.013,2.702"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M15.43,11l-0.43,6"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M8.57,11l0.43,6"
        ></path>
      </g>
    </svg>
  );
};
export const IconClose = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M8,8l8,8"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16,8l-8,8"
        ></path>
      </g>
    </svg>
  );
};
export const IconConfirmAppointment = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M18.364,5.63604c3.51472,3.51472 3.51472,9.2132 0,12.7279c-3.51472,3.51472 -9.2132,3.51472 -12.7279,0c-3.51472,-3.51472 -3.51472,-9.2132 -1.77636e-15,-12.7279c3.51472,-3.51472 9.2132,-3.51472 12.7279,-1.77636e-15"></path>
        <path d="M13.803,14.746l-2.254,2.254l-1.353,-1.353"></path>
        <path d="M9,9.364h-1c-0.828,0 -1.5,0.672 -1.5,1.5v8.136"></path>
        <path d="M15,9.364h1c0.828,0 1.5,0.672 1.5,1.5v8.136"></path>
        <path d="M13.905,10.727h-3.809c-0.606,0 -1.096,-0.49 -1.096,-1.095v-0.537c0,-0.605 0.49,-1.095 1.095,-1.095h3.809c0.606,0 1.096,0.49 1.096,1.095v0.536c0,0.606 -0.49,1.096 -1.095,1.096Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconKick = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M11,19.392v-11.889c0,-0.69 -0.355,-1.331 -0.94,-1.696l-4,-2.5c-1.332,-0.832 -3.06,0.125 -3.06,1.696v11.888c0,0.69 0.355,1.331 0.94,1.696l4,2.5c1.332,0.833 3.06,-0.125 3.06,-1.695Z"></path>
        <path d="M15,11h6"></path>
        <path d="M19,13l2,-2l-2,-2"></path>
        <path d="M11,19h4c1.105,0 2,-0.895 2,-2v-1"></path>
        <path d="M17,6v-1c0,-1.105 -0.895,-2 -2,-2h-10"></path>
      </g>
      <path fill="none" d="M24,24h-24v-24h24v24Z"></path>
    </svg>
  );
};
export const IconLegal = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M10.03,5h-5.53"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M13.4412,3.58579c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,-4.44089e-16"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19.5,5h-5.47"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4.5,20.75h15"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12.03,7l-0.03,13.75"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M8.814,13.227c0.114,0.18 0.179,0.391 0.159,0.603c-0.167,1.778 -1.663,3.17 -3.485,3.17c-1.821,0 -3.317,-1.391 -3.485,-3.169c-0.02,-0.212 0.046,-0.427 0.161,-0.607l3.324,-5.224c0,0 2.619,4.115 3.326,5.227Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M8.94,13.5h-6.9"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21.841,13.227c0.114,0.18 0.179,0.391 0.159,0.603c-0.167,1.778 -1.663,3.17 -3.485,3.17c-1.821,0 -3.317,-1.391 -3.485,-3.169c-0.02,-0.212 0.046,-0.427 0.161,-0.607l3.324,-5.224c0,0 2.618,4.115 3.326,5.227Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21.97,13.5h-6.91"
        ></path>
      </g>
    </svg>
  );
};
export const IconDeny = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.364,5.636l-12.728,12.728l12.728,-12.728Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,3v0c-4.971,0 -9,4.029 -9,9v0c0,4.971 4.029,9 9,9v0c4.971,0 9,-4.029 9,-9v0c0,-4.971 -4.029,-9 -9,-9Z"
        ></path>
      </g>
    </svg>
  );
};
export const IconLive = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  return (
    <svg
      id="Rec"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0,0H24V24H0Z" fill="none" />
      <circle cx="5" cy="5" r="5" transform="translate(7 7)" fill={color} />
    </svg>
  );
};
export const IconUsers = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M3,18v0c0,-2.209 1.791,-4 4,-4h4c2.209,0 4,1.791 4,4v0"></path>
        <path d="M16,4.651c1.381,0 2.5,1.119 2.5,2.5c0,1.381 -1.119,2.5 -2.5,2.5"></path>
        <path d="M11.4049,4.99613c1.32817,1.32817 1.32817,3.48157 0,4.80974c-1.32817,1.32817 -3.48157,1.32817 -4.80974,0c-1.32817,-1.32817 -1.32817,-3.48157 0,-4.80974c1.32817,-1.32817 3.48157,-1.32817 4.80974,0"></path>
        <path d="M17,13c2.209,0 4,1.791 4,4v0"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconDocument = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M18.414,6.414l-2.828,-2.828c-0.375,-0.375 -0.884,-0.586 -1.414,-0.586h-7.172c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h10c1.105,0 2,-0.895 2,-2v-11.172c0,-0.53 -0.211,-1.039 -0.586,-1.414Z"></path>
        <path d="M19,8h-4c-0.552,0 -1,-0.448 -1,-1v-4"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
export const IconDetails = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M20,20v-14.222c0,-0.982 -0.796,-1.778 -1.778,-1.778h-12.444c-0.982,0 -1.778,0.796 -1.778,1.778v10.667c0,0.982 0.796,1.778 1.778,1.778h10.889l3.333,1.777Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.998,14.791c-0.096,0 -0.174,0.078 -0.173,0.174c0,0.096 0.078,0.174 0.174,0.174c0.096,0 0.174,-0.078 0.174,-0.174c0.001,-0.096 -0.077,-0.174 -0.175,-0.174"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.999,12.076v-0.24c0,-0.787 0.486,-1.213 0.974,-1.54c0.476,-0.32 0.953,-0.738 0.953,-1.509c0,-1.064 -0.862,-1.926 -1.926,-1.926c-1.064,0 -1.927,0.86 -1.927,1.924"
        ></path>
      </g>
    </svg>
  );
};
export const IconCalendarNotFound = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M15,3v2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7,3v2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,9h16"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19,10v-3c0,-1.657 -1.343,-3 -3,-3h-10c-1.657,0 -3,1.343 -3,3v9c0,1.657 1.343,3 3,3h5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19.121,13.879c1.298,1.298 1.158,3.487 -0.418,4.594c-1.003,0.704 -2.404,0.704 -3.407,0c-1.577,-1.106 -1.716,-3.296 -0.418,-4.594c1.172,-1.172 3.072,-1.172 4.243,1.77636e-15"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M21,20l-1.88,-1.88"
        ></path>
      </g>
    </svg>
  );
};
export const Icon404 = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.347,16.267v-9.847c0,-1.332 -1.079,-2.411 -2.411,-2.411v0c-1.099,0 -2.058,0.743 -2.334,1.806l-2.49,9.61"
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeWidth={stroke}
        d="M8.27191,13.7281c1.43479,1.43479 1.43479,3.76103 0,5.19582c-1.43479,1.43479 -3.76103,1.43479 -5.19582,0c-1.43479,-1.43479 -1.43479,-3.76103 -8.88178e-16,-5.19582c1.43479,-1.43479 3.76103,-1.43479 5.19582,0"
      ></path>
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M14.653,16.267v-9.847c0,-1.332 1.079,-2.411 2.411,-2.411v0c1.099,0 2.058,0.743 2.334,1.806l2.49,9.61"></path>
        <path d="M14.653,10c0,-1.468 -1.188,-2.658 -2.653,-2.658c-1.465,0 -2.653,1.19 -2.653,2.658"></path>
        <path d="M9.347,16c0,-1.468 1.188,-2.658 2.653,-2.658c1.465,0 2.653,1.19 2.653,2.658"></path>
      </g>
      <path
        fill="none"
        stroke={color}
        strokeWidth={stroke}
        d="M20.9239,13.7281c1.43479,1.43479 1.43479,3.76103 0,5.19582c-1.43479,1.43479 -3.76103,1.43479 -5.19582,0c-1.43479,-1.43479 -1.43479,-3.76103 0,-5.19582c1.43479,-1.43479 3.76103,-1.43479 5.19582,0"
      ></path>
      <path d="M0,0h24v24h-24Z" fill="none"></path>
    </svg>
  );
};
export const Icon500 = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,13.12v-3.74"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M11.999,16.125c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.029,4.139l7.659,13.404c0.89,1.558 -0.235,3.497 -2.029,3.497h-15.318c-1.795,0 -2.92,-1.939 -2.029,-3.497l7.659,-13.404c0.897,-1.571 3.161,-1.571 4.058,8.88178e-16Z"
        ></path>
      </g>
    </svg>
  );
};
export const IconCodeSuccess = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M17,21c-2.761,0 -5,-2.239 -5,-5c0,-2.761 2.239,-5 5,-5c2.762,0 5,2.239 5,5c0,2.761 -2.238,5 -5,5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19.222,14.889l-2.778,2.778l-1.666,-1.667"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.849,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.383,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12.265,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.384,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.682,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.383,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,13h-4c-1.105,0 -2,-0.895 -2,-2v-6c0,-1.105 0.895,-2 2,-2h14c1.105,0 2,0.895 2,2v3"
        ></path>
      </g>
    </svg>
  );
};
export const IconCodeError = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M17,21c-2.761,0 -5,-2.239 -5,-5c0,-2.761 2.239,-5 5,-5c2.762,0 5,2.239 5,5c0,2.761 -2.238,5 -5,5"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.849,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.383,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12.265,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.384,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.682,7.735c0.146,0.146 0.146,0.384 0,0.53c-0.146,0.146 -0.384,0.146 -0.53,0c-0.146,-0.146 -0.146,-0.384 0,-0.53c0.146,-0.147 0.383,-0.147 0.53,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,13h-4c-1.105,0 -2,-0.895 -2,-2v-6c0,-1.105 0.895,-2 2,-2h14c1.105,0 2,0.895 2,2v3"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.41,14.59l-2.82,2.82"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.41,17.41l-2.82,-2.82"
        ></path>
      </g>
    </svg>
  );
};
export const IconEmail = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0.028h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,9.703v9.297c0,1.105 0.895,2 2,2h13.999c1.105,0 2,-0.895 2,-2v-9.297"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.12,14.88l-5.53,5.53"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M20.41,20.41l-5.53,-5.53"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M6,6.622l-2.114,1.422c-1.178,0.792 -1.178,2.526 0,3.319l5.874,3.952c1.354,0.911 3.124,0.911 4.478,0l5.874,-3.952c1.178,-0.793 1.178,-2.526 0,-3.319l-2.112,-1.422"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18,12.785v-8.883c0,-0.552 -0.448,-1 -1,-1h-10c-0.552,0 -1,0.448 -1,1v8.883"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,6.5h6"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,10h6"
        ></path>
      </g>
    </svg>
  );
};
export const IconTicket = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.083,21.813c-0.738,0 -1.437,-0.163 -2.065,-0.454l-3.018,0.641l0.631,-3.024c-0.293,-0.63 -0.457,-1.332 -0.457,-2.072c-4.44089e-16,-2.708 2.195,-4.904 4.904,-4.904c2.709,0 4.904,2.196 4.904,4.904c0,2.708 -2.196,4.904 -4.904,4.904"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M6.612,12.019c-0.393,-0.855 -0.612,-1.792 -0.612,-2.779c0,-4.017 3.598,-7.24 8,-7.24c4.402,0 8,3.223 8,7.24c0,2.374 -1.262,4.464 -3.199,5.782c0.001,0.756 -0.001,1.775 -0.001,2.845l-3.139,-1.546c-0.537,0.103 -1.092,0.159 -1.661,0.159c-0.711,0 -1.398,-0.092 -2.056,-0.25"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4.79536,16.9646c0.0195262,0.0195262 0.0195262,0.0511845 0,0.0707107c-0.0195262,0.0195262 -0.0511845,0.0195262 -0.0707107,0c-0.0195262,-0.0195262 -0.0195262,-0.0511845 0,-0.0707107c0.0195262,-0.0195262 0.0511845,-0.0195262 0.0707107,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.04536,16.9646c0.0195262,0.0195262 0.0195262,0.0511845 0,0.0707107c-0.0195262,0.0195262 -0.0511845,0.0195262 -0.0707107,0c-0.0195262,-0.0195262 -0.0195262,-0.0511845 0,-0.0707107c0.0195262,-0.0195262 0.0511845,-0.0195262 0.0707107,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.29536,16.9646c0.0195262,0.0195262 0.0195262,0.0511845 0,0.0707107c-0.0195262,0.0195262 -0.0511845,0.0195262 -0.0707107,0c-0.0195262,-0.0195262 -0.0195262,-0.0511845 0,-0.0707107c0.0195262,-0.0195262 0.0511845,-0.0195262 0.0707107,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14,10.044c0,-0.657 0.406,-1.013 0.813,-1.287c0.398,-0.268 0.796,-0.617 0.796,-1.26c0,-0.889 -0.72,-1.609 -1.609,-1.609c-0.889,0 -1.609,0.72 -1.609,1.609"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14,12.458c-0.055,0 -0.1,0.045 -0.1,0.1c0,0.055 0.045,0.1 0.1,0.1c0.055,0 0.1,-0.045 0.1,-0.1c0,-0.055 -0.045,-0.1 -0.1,-0.1"
        ></path>
      </g>
    </svg>
  );
};
export const IconSupport = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19.56,7.119c1.92,2.97 1.919,6.794 0.001,9.763"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M15.5476,8.45245c1.95926,1.95926 1.95926,5.13585 0,7.09511c-1.95926,1.95926 -5.13585,1.95926 -7.09511,0c-1.95926,-1.95926 -1.95926,-5.13585 0,-7.09511c1.95926,-1.95926 5.13585,-1.95926 7.09511,0"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.321,7.551l2.584,-3.139c0.376,-0.457 1.064,-0.49 1.483,-0.072l1.273,1.273c0.419,0.419 0.385,1.107 -0.072,1.483l-3.139,2.584"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.679,16.449l-2.584,3.139c-0.376,0.457 -1.064,0.49 -1.483,0.072l-1.273,-1.273c-0.419,-0.419 -0.385,-1.107 0.072,-1.483l3.139,-2.584"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.551,9.679l-3.14,-2.584c-0.457,-0.376 -0.49,-1.064 -0.072,-1.483l1.273,-1.273c0.419,-0.419 1.107,-0.385 1.483,0.072l2.584,3.139"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.449,14.321l3.139,2.584c0.457,0.376 0.49,1.064 0.072,1.483l-1.273,1.273c-0.419,0.419 -1.107,0.385 -1.483,-0.072l-2.584,-3.139"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.882,19.561c-2.969,1.918 -6.794,1.919 -9.763,-0.001"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4.439,7.118c-1.918,2.969 -1.919,6.793 0.001,9.763"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.881,4.44c-2.97,-1.92 -6.794,-1.919 -9.763,-0.001"
        ></path>
      </g>
    </svg>
  );
};
export const Youmain = (props: IconProps) => {
  const color = props.fill || 'black';
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 338.3 71.5"
    >
      <path
        fill={color}
        d="M43.9,42.1V64h-13V42.1L11.7,6.9h14.8l10.9,22l10.8-22H63L43.9,42.1z"
      />
      <path
        fill={color}
        d="M137.1,23.2V64h-12.2v-3.8c-2.2,3-6.3,5-11.7,5c-8.2,0-15.2-5.9-15.2-16.9v-25h12.2v23.2c0,5,3.2,7.4,7.1,7.4
	c4.5,0,7.6-2.6,7.6-8.4V23.2H137.1z"
      />
      <path
        fill={color}
        d="M207.2,39v25h-12.2V40c0-4.1-2-6.7-5.9-6.7c-4.1,0-6.4,2.9-6.4,7.7v23h-12.2V40c0-4.1-1.9-6.7-5.9-6.7
	c-4.1,0-6.4,2.9-6.4,7.7v23H146V23.2h12.2V27c1.9-2.8,5.5-4.9,10.9-4.9c4.8,0,8.4,1.9,10.7,5.4c2.3-3.3,6-5.4,11.8-5.4
	C201,22.1,207.2,28.8,207.2,39z"
      />
      <path
        fill={color}
        d="M257.4,23.2V64h-12.2v-3.8c-2.7,3.1-6.7,5-12.1,5c-10.7,0-19.5-9.4-19.5-21.5s8.8-21.5,19.5-21.5
	c5.5,0,9.5,1.9,12.1,5v-3.8H257.4z M245.1,43.6c0-6.1-4.1-9.9-9.7-9.9s-9.7,3.8-9.7,9.9c0,6.1,4.1,9.9,9.7,9.9
	S245.1,49.7,245.1,43.6z"
      />
      <path fill={color} d="M266.3,42h12.2v22h-12.2V42z" />
      <path
        fill={color}
        d="M326.6,39v25h-12.2V40.8c0-5-3.2-7.4-7.1-7.4c-4.5,0-7.6,2.6-7.6,8.4V64h-12.2V23.2h12.2v3.8
	c2.2-3,6.3-5,11.7-5C319.6,22.1,326.6,28,326.6,39z"
      />
      <circle fill={color} cx="73.7" cy="40.5" r="7.5" />
      <circle fill={color} cx="272.5" cy="29.6" r="7.5" />
      <path
        fill={color}
        d="M85.5,64c-0.3-6.2-5.5-11.2-11.7-11.2S62.3,57.8,62,64H85.5z"
      />
    </svg>
  );
};
export const IconSlotNotFound = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16,3v2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7,3v2"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3,9h17"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M20,10v-3c0,-1.657 -1.343,-3 -3,-3h-11c-1.657,0 -3,1.343 -3,3v9c0,1.657 1.343,3 3,3h3"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.694,13.605l3.046,5.414c0.75,1.333 -0.213,2.981 -1.743,2.981h-6.091c-1.53,0 -2.493,-1.647 -1.743,-2.981l3.046,-5.414c0.763,-1.36 2.72,-1.36 3.485,1.77636e-15Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.951,17.42v-1.835"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.95,19.444c-0.05,0 -0.091,0.041 -0.09,0.091c0,0.05 0.041,0.091 0.091,0.091c0.05,0 0.09,-0.041 0.09,-0.091c0,-0.05 -0.04,-0.091 -0.091,-0.091"
        ></path>
      </g>
    </svg>
  );
};
export const IconBrowser = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M20,10v-5c0,-1.105 -0.895,-2 -2,-2h-13c-1.105,0 -2,0.895 -2,2v13c0,1.105 0.895,2 2,2h4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M5.825,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M8.368,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.003,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c-1.77636e-15,-0.003 -0.002,-0.005 -0.005,-0.005"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M10.907,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M3.046,8h16.954"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.84,13.975l2.912,5.175c0.717,1.274 -0.204,2.85 -1.666,2.85h-5.823c-1.463,0 -2.383,-1.574 -1.666,-2.85l2.912,-5.175c0.729,-1.3 2.6,-1.3 3.331,1.77636e-15Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M17.174,17.622v-1.754"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M17.173,19.557c-0.048,0 -0.087,0.039 -0.086,0.087c0,0.048 0.039,0.087 0.087,0.087c0.048,0 0.086,-0.039 0.086,-0.087c0,-0.048 -0.039,-0.087 -0.087,-0.087"
        ></path>
      </g>
    </svg>
  );
};
export const IconMore = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(4106 8303)">
        <rect
          width={size}
          height={size}
          transform="translate(-4106 -8303)"
          fill="none"
        />
        <circle
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4103 -8293)"
          fill={color}
        />
        <circle
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4096 -8293)"
          fill={color}
        />
        <circle
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4089 -8293)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconEvent = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M20,17h-16c-0.552,0 -1,-0.448 -1,-1v-1c0,-0.552 0.448,-1 1,-1h16c0.552,0 1,0.448 1,1v1c0,0.552 -0.448,1 -1,1Z"></path>
        <path d="M5,4c-0.552,0 -1,0.448 -1,1v9h16v-9c0,-0.552 -0.448,-1 -1,-1"></path>
        <path d="M16,21l-1,-4"></path>
        <path d="M8,21l1,-4"></path>
        <path d="M16,5.5h-8c-0.552,0 -1,-0.448 -1,-1v-1c0,-0.552 0.448,-1 1,-1h8c0.552,0 1,0.448 1,1v1c0,0.552 -0.448,1 -1,1Z"></path>
        <path d="M19,4h-2"></path>
        <path d="M7,4h-2"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconCopy = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.006,12.003l2.994,2.998l-3,3.002"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,15h-4c-1.105,0 -2,-0.895 -2,-2v-8c0,-1.105 0.895,-2 2,-2h8c1.105,0 2,0.895 2,2v4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M19,21h-8c-1.105,0 -2,-0.895 -2,-2v-8c0,-1.105 0.895,-2 2,-2h8c1.105,0 2,0.895 2,2v8c0,1.105 -0.895,2 -2,2Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9,15h8"
        ></path>
      </g>
    </svg>
  );
};
export const IconWebinarRelator = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M6.051,13.101l4.051,-4.051c-0.001,-2.236 -1.814,-4.05 -4.051,-4.05c-2.237,0 -4.051,1.814 -4.051,4.051c0,2.237 1.814,4.05 4.051,4.05Z"></path>
        <path d="M9.865,9.287l5.061,6.694c0.278,0.368 0.242,0.884 -0.084,1.21l-0.652,0.652c-0.326,0.326 -0.842,0.361 -1.21,0.084l-6.694,-5.061"></path>
        <path d="M22,11l-1.746,-1.746c-0.951,-0.951 -2.492,-0.951 -3.443,0v0c-0.951,0.951 -0.951,2.492 0,3.443l1.262,1.262l1.205,1.205c0.982,0.982 0.982,2.575 0,3.557v0c-0.982,0.982 -2.575,0.982 -3.557,0l-1.205,-1.205"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
export const IconWebinarParticipant = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M21,7.542c0,-2.508 -2.034,-4.542 -4.542,-4.542c-2.508,0 -4.54,2.034 -4.542,4.54l4.542,4.542c2.508,0 4.542,-2.032 4.542,-4.54Z"></path>
        <path d="M19,20l-16,-16"></path>
        <path d="M7.912,13.455l-1.403,1.856c-0.312,0.413 -0.271,0.991 0.094,1.357l0.731,0.731c0.365,0.366 0.944,0.405 1.357,0.094l1.856,-1.403"></path>
        <path d="M16.19,11.82l-3.06,2.31"></path>
        <path d="M12.18,7.81l-2.31,3.06"></path>
        <path d="M6.968,17.032l-2.411,2.411"></path>
      </g>
      <path fill="none" d="M24,24h-24v-24h24v24Z"></path>
    </svg>
  );
};
export const IconLate = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,21c4.95,0 9,-4.05 9,-9c0,-4.95 -4.05,-9 -9,-9"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,21c-2.931,0 -5.538,-1.426 -7.184,-3.612"
        ></path>
        <path
          stroke={color}
          strokeDasharray="0 3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M4.816,17.388c-1.134,-1.506 -1.816,-3.369 -1.816,-5.388c0,-4.95 4.05,-9 9,-9"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.359,15.639l4.922,-2.936v-6.328"
        ></path>
      </g>
    </svg>
  );
};
export const IconWebinarFull = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M13.7678,10.9822c0.976311,0.976311 0.976311,2.55922 0,3.53553c-0.976311,0.976311 -2.55922,0.976311 -3.53553,0c-0.976311,-0.976311 -0.976311,-2.55922 0,-3.53553c0.976311,-0.976311 2.55922,-0.976311 3.53553,0"></path>
        <path d="M19.2538,4.98223c0.976311,0.976311 0.976311,2.55922 0,3.53553c-0.976311,0.976311 -2.55922,0.976311 -3.53553,0c-0.976311,-0.976311 -0.976311,-2.55922 0,-3.53553c0.976311,-0.976311 2.55922,-0.976311 3.53553,0"></path>
        <path d="M18,12.25c1.533,0 3,0.533 4,1.333"></path>
        <path d="M8.28177,4.98223c0.976311,0.976311 0.976311,2.55922 0,3.53553c-0.976311,0.976311 -2.55922,0.976311 -3.53553,0c-0.976311,-0.976311 -0.976311,-2.55922 0,-3.53553c0.976311,-0.976311 2.55922,-0.976311 3.53553,0"></path>
        <path d="M2,13.583c1,-0.8 2.467,-1.333 4,-1.333"></path>
        <path d="M16.591,19.75c-1.165,-0.901 -2.839,-1.5 -4.591,-1.5c-1.752,0 -3.426,0.599 -4.591,1.5"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
export const IconActivateAudio = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M9.499,14l2.5,2v-8.025l-2.5,2.025h-1.5c-0.552,0 -1,0.448 -1,1v2c0,0.552 0.448,1 1,1h1.5Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.972,8c1.224,0.911 2.027,2.357 2.027,4c0,1.643 -0.804,3.089 -2.027,4"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18.363,18.364c3.515,-3.515 3.515,-9.213 0,-12.728"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.972,3.534c-3.553,-1.247 -7.722,-0.228 -10.223,3.128c-2.331,3.129 -2.331,7.548 -8.88178e-16,10.677c2.5,3.355 6.669,4.374 10.222,3.127"
        ></path>
      </g>
    </svg>
  );
};
export const IconWebinarQuestions = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M3,11.291c0,2.683 1.42,5.045 3.599,6.534c-0.001,0.855 0.001,2.007 0.001,3.216l3.531,-1.747c0.604,0.116 1.228,0.179 1.869,0.179c4.952,0 9,-3.643 9,-8.182c0,-4.539 -4.048,-8.182 -9,-8.182c-4.952,0 -9,3.643 -9,8.182Z"></path>
        <path d="M11.899,12.328v-0.207c0,-0.678 0.419,-1.045 0.839,-1.327c0.41,-0.276 0.821,-0.636 0.821,-1.3c0,-0.917 -0.743,-1.659 -1.659,-1.659c-0.916,0 -1.66,0.741 -1.66,1.658"></path>
        <path d="M11.898,14.667c-0.083,0 -0.15,0.067 -0.149,0.15c0,0.083 0.067,0.15 0.15,0.15c0.083,0 0.15,-0.067 0.15,-0.15c-1.77636e-15,-0.083 -0.066,-0.15 -0.151,-0.15"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
export const IconCompany = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M17,21v-17c0,-0.552 -0.448,-1 -1,-1h-8c-0.552,0 -1,0.448 -1,1v17"></path>
        <path d="M7,9h-4c-0.552,0 -1,0.448 -1,1v11"></path>
        <path d="M22,21v-11c0,-0.552 -0.448,-1 -1,-1h-4"></path>
        <path d="M9.5,17h5"></path>
        <path d="M13.5,17v4"></path>
        <path d="M10.5,21v-4"></path>
        <path d="M10,10.5h4"></path>
        <path d="M10,13.5h4"></path>
        <path d="M10,7.5h4"></path>
        <path d="M5,13.5h2"></path>
        <path d="M5,17h2"></path>
        <path d="M17,13.5h2"></path>
        <path d="M17,17h2"></path>
        <path d="M23,21h-22"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconStore = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24v-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M14.072,10.493c0,0.985 -0.927,1.784 -2.071,1.784c-1.144,0 -2.071,-0.798 -2.071,-1.784v0.039c-0.002,0.964 -0.93,1.746 -2.073,1.746c-1.37,-0.01 -2.264,-1.366 -1.67,-2.536l1.384,-2.726c0.316,-0.621 0.979,-1.016 1.707,-1.016h5.443c0.728,0 1.391,0.395 1.707,1.016l1.385,2.726c0.594,1.169 -0.299,2.526 -1.67,2.536c-1.144,0 -2.071,-0.781 -2.071,-1.746v-0.039"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M18,21h-12c-1.657,0 -3,-1.343 -3,-3v-12c0,-1.657 1.343,-3 3,-3h12c1.657,0 3,1.343 3,3v12c0,1.657 -1.343,3 -3,3Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M13.519,18v-2.126c0,-0.839 -0.68,-1.519 -1.519,-1.519c-0.839,0 -1.519,0.68 -1.519,1.519v2.126"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M6.533,18h10.935"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M7.248,12.178v5.822"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M16.752,18v-5.822"
        ></path>
      </g>
    </svg>
  );
};
export const IconWall = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 24 24)">
        <rect
          width="24"
          height="24"
          fill="none"
          transform="matrix(-1 1.22465e-16 -1.22465e-16 -1 0 0)"
        ></rect>
      </g>
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <line x1="11.99" x2="11.99" y1="20.98" y2="3"></line>
        <line x1="12" x2="3" y1="13.78" y2="13.78"></line>
        <line x1="11.99" x2="20.99" y1="9.28" y2="9.28"></line>
        <rect width="18" height="18" x="3" y="3" rx="1.10456" ry="0"></rect>
      </g>
    </svg>
  );
};
export const IconSubscription = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M3,8h18"></path>
        <path d="M9.5,12h-2.5"></path>
        <path d="M9,18h-3c-1.657,0 -3,-1.343 -3,-3v-8c0,-1.657 1.343,-3 3,-3h12c1.657,0 3,1.343 3,3v4"></path>
        <path d="M13.083,19.767l-0.144,-1.085c0,0 0,0 0,-0.001l-0.665,-0.87c-0.366,-0.479 -0.366,-1.144 0,-1.623l0.665,-0.87c0,0 0,0 0,-0.001l0.145,-1.086c0.08,-0.598 0.55,-1.068 1.148,-1.148l1.085,-0.144c0,0 0,0 0.001,0l0.87,-0.665c0.479,-0.366 1.144,-0.366 1.623,0l0.87,0.665c0,0 0,0 0.001,0l1.086,0.145c0.598,0.08 1.068,0.55 1.148,1.148l0.144,1.085c0,0 0,0 0,0.001l0.665,0.87c0.366,0.479 0.366,1.144 0,1.623l-0.665,0.87c0,0 0,0 0,0.001l-0.145,1.086c-0.08,0.598 -0.55,1.068 -1.148,1.148l-1.085,0.144c0,0 0,0 -0.001,0l-0.87,0.665c-0.479,0.366 -1.144,0.366 -1.623,0l-0.87,-0.665c0,0 0,0 -0.001,0l-1.086,-0.145c-0.597,-0.078 -1.068,-0.55 -1.148,-1.148Z"></path>
        <path d="M18.556,16.222l-1.945,1.945l-1.167,-1.167"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconGear = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M13.7678,10.2322c0.976311,0.976311 0.976311,2.55922 0,3.53553c-0.976311,0.976311 -2.55922,0.976311 -3.53553,0c-0.976311,-0.976311 -0.976311,-2.55922 0,-3.53553c0.976311,-0.976311 2.55922,-0.976311 3.53553,0"></path>
        <path d="M14.849,4.12l0.583,0.194c0.534,0.178 0.895,0.678 0.895,1.241v0.837c0,0.712 0.568,1.293 1.28,1.308l0.838,0.018c0.485,0.01 0.925,0.289 1.142,0.723l0.275,0.55c0.252,0.504 0.153,1.112 -0.245,1.51l-0.592,0.592c-0.503,0.503 -0.512,1.316 -0.02,1.83l0.58,0.606c0.336,0.351 0.45,0.858 0.296,1.319l-0.194,0.583c-0.178,0.534 -0.678,0.895 -1.241,0.895h-0.837c-0.712,0 -1.293,0.568 -1.308,1.28l-0.018,0.838c-0.01,0.485 -0.289,0.925 -0.723,1.142l-0.55,0.275c-0.504,0.252 -1.112,0.153 -1.51,-0.245l-0.592,-0.592c-0.503,-0.503 -1.316,-0.512 -1.83,-0.02l-0.606,0.58c-0.351,0.336 -0.858,0.45 -1.319,0.296l-0.583,-0.194c-0.534,-0.178 -0.895,-0.678 -0.895,-1.241v-0.837c0,-0.712 -0.568,-1.293 -1.28,-1.308l-0.838,-0.018c-0.485,-0.01 -0.925,-0.289 -1.142,-0.723l-0.275,-0.55c-0.252,-0.504 -0.153,-1.112 0.245,-1.51l0.592,-0.592c0.503,-0.503 0.512,-1.316 0.02,-1.83l-0.58,-0.606c-0.337,-0.352 -0.451,-0.86 -0.297,-1.32l0.194,-0.583c0.178,-0.534 0.678,-0.895 1.241,-0.895h0.837c0.712,0 1.293,-0.568 1.308,-1.28l0.018,-0.838c0.012,-0.485 0.29,-0.925 0.724,-1.142l0.55,-0.275c0.504,-0.252 1.112,-0.153 1.51,0.245l0.592,0.592c0.503,0.503 1.316,0.512 1.83,0.02l0.606,-0.58c0.351,-0.335 0.859,-0.449 1.319,-0.295Z"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
    </svg>
  );
};
export const IconGoTo = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        strokeLinecap="round"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinejoin="round"
      >
        <path d="M12,12l9,-9v7v-7h-7"></path>
        <path d="M9,3h-4c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-4"></path>
      </g>
      <path fill="none" d="M0,0h24v24h-24Z"></path>
    </svg>
  );
};
