import styled, {css} from 'styled-components';

export const Header = styled.div<{$flex?: boolean; $sticky?: boolean}>`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: 36px;
  background-color: ${(props) => props.theme.colors.background};

  ${(props) =>
    props.$flex &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    `}

  ${(props) =>
    props.$sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
      @media ${(props) => props.theme.responsive.phone} {
        padding-bottom: calc(
          ${(props) => props.theme.layout.frontendSpace} / 2
        );
      }
    `}

  a {
    text-decoration: none;
  }
`;
