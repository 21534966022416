import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';

if (process.env.NEXT_PUBLIC_GTAG) {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GTAG, {
    testMode: process.env.NODE_ENV !== 'production',
  });

  ReactGA.set({anonymizeIp: true});
}

if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
  const siteId = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID);
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

export const pageview = (pathname: string) => {
  if (!process.env.NEXT_PUBLIC_GTAG) {
    return;
  }

  ReactGA.send({hitType: 'pageview', page: pathname});
};

export const usePageview = (pathname: string) => {
  useEffect(() => {
    pageview(pathname);
  }, [pathname]);
};

export const setCustomDimension = (
  customDimensionName: string,
  customDimensionValue: number
) => {
  if (!process.env.NEXT_PUBLIC_GTAG) {
    return;
  }

  ReactGA.set({
    user_properties: {
      [`${customDimensionName}`]: customDimensionValue,
    },
  });
};

export const sendCustomEvent = (
  cat: string,
  action: string,
  label: string,
  value?: number
) => {
  if (!process.env.NEXT_PUBLIC_GTAG) {
    return;
  }
  const customEvent: Record<'action' | 'category' | 'label', string> & {
    value?: number;
  } = {
    category: cat,
    action: action,
    label: label, // optional
  };

  if (value) {
    customEvent['value'] = value;
  }

  ReactGA.event(customEvent);
};
