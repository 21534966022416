import React, {forwardRef} from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';
import {IconGroup} from '../Icons';

type ButtonParticipantsProps = {
  invert: boolean;
  onClick?: () => void;
  toggle?: boolean;
  count: number;
  roomMaxSize: number | string;
};

const ButtonParticipants = forwardRef<
  HTMLButtonElement,
  ButtonParticipantsProps
>(({invert, onClick, toggle, count, roomMaxSize}, ref) => {
  return (
    <Container $invert={invert} $toggle={toggle} onClick={onClick} ref={ref}>
      <ParticipantsIndicator>
        {count}
        {roomMaxSize ? `/${roomMaxSize}` : ''}
      </ParticipantsIndicator>
      <IconGroup fill={invert ? '#fff' : '#000'} />
    </Container>
  );
});

export default ButtonParticipants;

const ParticipantsIndicator = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  padding: 7px 6px 0 0;
  letter-spacing: 1px;
  @media ${(props) => props.theme.responsive.phone} {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 2px;
    right: 3px;
    bottom: 0;
    font-size: 11px;
  }
`;

const Container = styled.button<{
  $invert: boolean;
  $toggle: boolean;
}>`
  position: relative;
  width: auto;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  border: 1px solid transparent;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: center;
  transition: all ease 0.2s;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};

  @media ${(props) => props.theme.responsive.tablet} {
    height: 38px;
  }

  & + button {
    margin-left: 24px;
    @media ${(props) => props.theme.responsive.tablet} {
      margin-left: 12px;
    }
  }

  &:hover {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.5);
  }

  ${(props) =>
    props.$invert &&
    css`
      background-color: ${props.theme.colors.decline};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.decline)};
      }

      ${ParticipantsIndicator} {
        color: #fff;
      }
    `}

  ${(props) =>
    props.$invert &&
    props.$toggle &&
    css`
      background-color: ${props.theme.colors.success};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.success)};
      }

      ${ParticipantsIndicator} {
        color: #fff;
        @media ${(props) => props.theme.responsive.phone} {
          background-color: ${props.theme.colors.success};
        }
      }
    `}
`;
