import React, {useMemo, PropsWithChildren} from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  DefaultTheme,
} from 'styled-components';
import merge from 'deepmerge';

import defaultTheme from '../config/theme';
import {useCompany} from './CompanyContext';
import {getContrastedColor} from '../utils/getContrastedColor';

export const ThemeProvider = (props: PropsWithChildren<{}>) => {
  const [company] = useCompany();

  const customTheme = useMemo(() => {
    if (!company?.primaryColor) {
      return defaultTheme;
    }

    const buttonTextColor = getContrastedColor(company.primaryColor);

    const customTheme: Partial<DefaultTheme> = {
      colors: {
        main:
          company.primaryColor !== '#ffffff' ? company.primaryColor : '#cdcdcd',
        buttonText: buttonTextColor,
      },
    };

    return merge(defaultTheme, customTheme || {});
  }, [company]);

  return (
    <StyledThemeProvider theme={customTheme}>
      <>{props.children}</>
    </StyledThemeProvider>
  );
};
