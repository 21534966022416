import {keyframes} from 'styled-components';

export const spin = keyframes`
    from {
        transform: rotate(0);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const slideInRight = keyframes`
    from {
        transform: translateX(20px);
        opacity: 0;
    }
    
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const appear = keyframes`
    from {
        opacity: 0;
        position: absolute;
        left: 40px;
    }
    
    to {
        opacity: 1;
        left: 50px;
    }
`;
export const appearSmall = keyframes`
    from {
        opacity: 0;
        position: absolute;
        left: -5px;
    }
    
    to {
        opacity: 1;
        left: 10px;
    }
`;
export const pulse = keyframes`
    0% {
        opacity: 1;
        transform: scale(0);
    }
    95% {
        opacity: 0.3;
        transform: scale(1.4);
    }
    100% {
        opacity: 0;
    }
`;
export const fadeToggle = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
`;

export const isSpeaking = keyframes`
    0% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(2.8);
    }
`;

export const noSpeaking = keyframes`
    0% {
        transform: scaleY(${Math.random() * (2.8 - 1) + 1});
    }
`;

export const enter = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;
export const exit = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.7);
    }
`;
