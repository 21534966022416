import React, {createContext, useContext, PropsWithChildren} from 'react';
import useSWR from 'swr';
import {
  fetchSubscription,
  fetchSubscriptionBilling,
  fetchSubscriptionPayment,
} from '../utils/api';
import {isValidSubscription} from '../utils/subscription';
import {useUser} from './UserContext';

interface SubscriptionContextType {
  subscription: Subscription | undefined | null;
  billing: SubscriptionBilling | undefined | null;
  payment: SubscriptionPayment | undefined | null;
  loading: boolean;
}

export const SubscriptionContext = createContext<SubscriptionContextType>({
  subscription: null,
  billing: null,
  payment: null,
  loading: false,
});

export const SubscriptionProvider = ({children}: PropsWithChildren<{}>) => {
  const {user} = useUser();

  const {data: subscription, isValidating: subscriptionLoading} = useSWR(
    user ? '/api/subscription' : null,
    fetchSubscription,
    {
      revalidateOnFocus: false,
    }
  );
  const {data: billing, isValidating: billingLoading} = useSWR(
    user ? '/api/subscription/billing' : null,
    fetchSubscriptionBilling,
    {
      revalidateOnFocus: false,
      // ignore errors because 404 means no existing billing infos
      shouldRetryOnError: false,
    }
  );
  const {data: payment, isValidating: paymentLoading} = useSWR(
    user ? '/api/subscription/payment' : null,
    fetchSubscriptionPayment,
    {
      revalidateOnFocus: false,
      // ignore errors because 404 means no existing billing infos
      shouldRetryOnError: false,
    }
  );

  const context = {
    subscription: subscription,
    billing: billing,
    payment: payment,
    loading: subscriptionLoading || billingLoading || paymentLoading,
  };

  return (
    <SubscriptionContext.Provider value={context}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription: () => SubscriptionContextType = () =>
  useContext(SubscriptionContext);

export const useRequiredSubscription = () => {
  const {subscription, payment, billing, loading} =
    useContext(SubscriptionContext);

  if (!loading && !isValidSubscription(subscription)) {
    throw new Error('Subscription non found');
  }

  if (!isValidSubscription(subscription)) {
    throw new Error('Subscription non found');
  }

  return {
    subscription,
    payment,
    billing,
    loading,
  };
};
