export const isSubscriptionWithNextPlan = (
  subscription: Subscription
): subscription is SubscriptionWithNextPlan => !!subscription.nextPlan;

export function isSetupRequired(rooms: number): boolean {
  if (rooms <= 0) {
    return true;
  }
  return false;
}

export const isTrial = (code: string) => code.includes('trial');

export const isDowngrade = (subscription: Subscription) =>
  !!subscription.nextPlan &&
  subscription.nextPlan.price < subscription.plan.price;

export const isValidSubscription = (
  subscription: Subscription | null | undefined
): subscription is Subscription => !!subscription;

export function canAddUser(users: number, company: Company) {
  if (users < company.usersLimit) {
    return true;
  }

  return false;
}

export function canAddRoom(rooms: number, subscription: Subscription) {
  if (subscription.rooms === -1) {
    return true;
  }

  if (subscription.rooms - rooms > 0) {
    return true;
  }

  return false;
}
