import React, {useState, useEffect} from 'react';
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import {darken} from 'polished';
import {Button} from '../Buttons';
import {TextS} from '../Typography';

const CookieBanner = () => {
  const [hasAccepted, setCookie] = useState(false);

  useEffect(() => {
    if (storage.getItem('cookieconsent_status')) {
      setCookie(true);
    }
  }, [hasAccepted]);

  const closeBanner = () => {
    setCookie(true);
    storage.setItem('cookieconsent_status', 'true');
  };

  if (hasAccepted) {
    return null;
  }

  return (
    <Container>
      <Content>
        <TextS>
          Questo sito web utilizza i cookie per assicurarti la migliore
          esperienza possibile.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://youmain.it/privacy-policy"
          >
            Scopri di più
          </a>
        </TextS>
      </Content>
      <Actions>
        <Button onClick={() => closeBanner()} title="Ok, ho capito" small />
      </Actions>
    </Container>
  );
};

export default CookieBanner;

const Container = styled.div`
  font-family: ${(props) => props.theme.typography.main};
  width: 100%;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: ${(props) => darken(0.1, props.theme.colors.background)};
`;

const Content = styled.div`
  display: block;

  ${TextS} {
    margin-bottom: 0;
    padding-right: 20px;
  }

  a {
    color: ${(props) => props.theme.colors.main};
    font-weight: 700;
  }
`;

const Actions = styled.div``;
