import axios from 'axios';
import {RoomDocument, RoomWaitingState} from '../types/video-room';

const isServer = typeof window === 'undefined';
const isBrowser = !isServer;
const BACKEND_URI = process.env.BACKEND_URI || 'http://localhost:8080';

export const http = axios.create({
  baseURL: isServer ? BACKEND_URI : '/',
  withCredentials: true,
});

// USER
export const login = async (
  id: Company['id'],
  username: string,
  password: string
) => {
  const response = await http.post('/api/login', {
    company: id,
    username: username,
    password: password,
  });
  return response.data;
};

export const logout = async () => {
  const response = await http.post('/api/logout');
  return response.data;
};

export const updatePassword = async (currentPassword, newPassword) => {
  const response = await http.put('/api/me/update-password', {
    currentPassword,
    newPassword,
  });
  return response.data;
};

export const updateCurrentUser = async (userData) => {
  const response = await http.put('/api/me', userData);
  return response.data;
};

export const fetchCurrentUser = async (
  token?: string
): Promise<UserOrGuest> => {
  const headers = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await http.get('/api/me', {headers});
  return response.data;
};

export const fetchUsers = async (): Promise<Users> => {
  const response = await http.get('/api/users');
  return response.data;
};

export const fetchUser = async (id: string): Promise<User> => {
  const response = await http.get(`/api/users/${id}`);
  return response.data;
};

export const updateUser = async (id: string, userData: UserFormData) => {
  const response = await http.post(`/api/users/${id}`, userData);
  return response.data;
};

export const createUser = async (userData: UserFormData) => {
  const response = await http.post(`/api/users/`, userData);
  return response.data;
};

export const deleteUser = async (id: string) => {
  const response = await http.delete(`/api/users/${id}`);
  return response.data;
};
export const fetchSubscription = async (): Promise<Subscription> => {
  const response = await http.get(`/api/subscription`);
  return response.data;
};

export const fetchSubscriptionBilling =
  async (): Promise<SubscriptionBilling | null> => {
    const response = await http.get(`/api/subscription/billing`);
    return response.data;
  };

export const updateSubscriptionBilling = async (
  data: any
): Promise<SubscriptionBilling | null> => {
  const response = await http.post(`/api/subscription/billing`, data);
  return response.data;
};

export const fetchSubscriptionPayment =
  async (): Promise<SubscriptionPayment> => {
    const response = await http.get(`/api/subscription/payment`);
    return response.data;
  };

export const fetchClientSecret =
  async (): Promise<SubscriptionClientSecret> => {
    const response = await http.get('/api/subscription/setup-intent');
    return response.data;
  };

export const createSubscriptionPayment = async (
  cardToken: string
): Promise<void> => {
  const response = await http.post('/api/subscription/payment', {
    stripeToken: cardToken,
  });
  // return response.data;
};

interface cartConfig {
  plan: SubscriptionType;
  pos: boolean;
  webinar: boolean;
  additionalUsers: number;
}

export const fetchCart = async (config: cartConfig): Promise<Cart> => {
  const response = await http.post('api/subscription/checkout-preview', config);
  return response.data;
};

export const confirmCheckout = async (config: cartConfig): Promise<Cart> => {
  const response = await http.post('api/subscription/checkout', config);
  return response.data;
};

export const forgotPassword = async (companyId: string, email: string) => {
  const response = await http.put('/api/password-reset-request', {
    company: companyId,
    email: email,
  });
  return response.data;
};

export const resetPassword = async (token: string, password: string) => {
  const response = await http.post('/api/password-reset', {
    token: token,
    password: password,
  });
  return response.data;
};

export const cancelSubscription = async (): Promise<void> => {
  const response = await http.post('api/subscription/cancel');
  // return response.data;
};

export const restoreSubscription = async (): Promise<void> => {
  const response = await http.post('api/subscription/restore');
  // return response.data;
};

// RESERVARTIONS
export const fetchReservations: () => Promise<Reservations> = async () => {
  const response = await http.get('/api/bo/reservations');
  return response.data;
};

export const fetchReservation = async (id: string): Promise<Reservation> => {
  const response = await http.get(`/api/bo/reservations/${id}`);
  return response.data;
};

export const deleteReservation = async (id: string) => {
  const response = await http.delete(`/api/bo/reservations/${id}`);
  return response.data;
};

export const addReservationParticipant = async (
  option: AddParticipantOptions
): Promise<Reservation> => {
  const response = await http.post(`/api/bo/reservation_participants`, option);
  return response.data;
};

export const deleteReservationParticipant = async (
  participantId: string
): Promise<Reservation> => {
  const response = await http.delete(
    `/api/bo/reservation_participants/${participantId}`
  );
  return response.data;
};

export const fetchReservationFromParticiapantId = async (
  participantId: string
): Promise<ParticipantReservation> => {
  const response = await http.get(
    `/api/reservation_participants/${participantId}`
  );
  return response.data;
};

// ROOMS
export const fetchRooms = async (): Promise<Room[]> => {
  const response = await http.get(`/api/rooms`);
  return response.data;
};

export const fetchCompanyRooms = async (companyId: string): Promise<Room[]> => {
  const response = await http.get(`/api/company/${companyId}/rooms`);
  return response.data;
};

export const fetchRoom = async (id: string): Promise<Room> => {
  const response = await http.get(`/api/room/${id}`);
  return response.data as Room;
};

export const updateRoom = async (id: string, data) => {
  const response = await http.post(`/api/room/${id}`, data);
  return response.data;
};

export const deleteRoom = async (id: string) => {
  const response = await http.delete(`/api/room/${id}`);
  return response.data;
};

// COMPANY
export const fetchCompanyConfig = async (
  host: string
): Promise<CompanyFullInfo> => {
  const response = await http.get(`/api/config/company`, {
    headers: {
      'x-origin': host,
    },
  });
  return response.data;
};

export const fetchCompany = async (id: string): Promise<CompanyFullInfo> => {
  const response = await http.get(`/api/company/${id}`);
  return response.data;
};

export const updateCompany = async (id: string, data: FormData | object) => {
  const response = await http.post(`/api/company/${id}`, data);
  return response.data;
};

export const fetchCompanyStores = async (id: string): Promise<Store[]> => {
  const response = await http.get(`/api/company/${id}/stores`);
  return response.data;
};

export const fetchCompanyPrivacy = async (
  id: string
): Promise<PrivacyPolicy | null> => {
  const response = await http.get(`/api/company/${id}/privacy`);

  return response.data;
};

export const updateCompanyPrivacy = async (
  id: string,
  privacyPolicy: string
) => {
  const response = await http.post(`/api/company/${id}/privacy`, {
    privacyPolicy,
  });
  return response.data;
};

// WEBINARS
export const fetchWebinars: () => Promise<Webinars> = async () => {
  const response = await http.get('/api/webinar');
  return response.data;
};

export const fetchStoreWebinars = async (id: string): Promise<Webinars> => {
  const response = await http.get(`/api/store/${id}/webinar`);
  return response.data;
};

export const fetchWebinar = async (id: string): Promise<Webinar> => {
  const response = await http.get(`/api/webinar/${id}`);
  return response.data;
};

interface NewWebinarData {
  title: string;
  seats: number;
  room: string;
  description: string;
  daytime: string;
  duration: number;
  visibility: number;
}
export const createWebinar = async (data: NewWebinarData) => {
  const response = await http.post(`/api/webinar`, data);
  return response.data;
};

export const updateWebinar = async (id: string, data: FormData | object) => {
  const response = await http.post(`/api/webinar/${id}`, data);
  return response.data;
};

export const fetchWebinarReservations = async (
  id: string
): Promise<WebinarRequests> => {
  const response = await http.get(`/api/webinar/${id}/reservation`);
  return response.data;
};

export const fetchWebinarReservation = async (
  id: string
): Promise<WebinarRequest> => {
  const response = await http.get(`/api/webinar-reservation/${id}`);
  return response.data;
};

export const sendWebinarReservation = async (
  webinar: string,
  data: WebinarRequest
) => {
  const response = await http.post(`/api/webinar/${webinar}/reservation`, data);
  return response.data;
};

export const deleteWebinarReservation = async (reservation: string) => {
  const response = await http.delete(`/api/webinar-reservation/${reservation}`);
  return response.data;
};

// STORE
export const fetchStoreConfig = async (
  host: string,
  slug: string
): Promise<Store> => {
  const response = await http.get(`/api/config/store/${slug}`, {
    headers: {
      'x-origin': host,
    },
  });
  return response.data;
};

export const fetchStores = async (): Promise<Store[]> => {
  const response = await http.get(`/api/stores`);
  return response.data;
};

export const fetchStore = async (id: string): Promise<Store> => {
  const response = await http.get(`/api/store/${id}`);
  return response.data;
};
export const fetchStoreTopics = async (
  id: string
): Promise<ReservationTopic[]> => {
  const response = await http.get(`/api/store/${id}/topics`);
  return response.data;
};

export const updateStore = async (id: string, data: FormData) => {
  const response = await http.post(`/api/store/${id}`, data);
  return response.data;
};

export const createStore = async (
  data:
    | Pick<SetupRoomRequest, 'name' | 'slug' | 'emailNotificationRule'>
    | FormData
) => {
  const response = await http.post(`/api/store`, data);
  return response.data;
};

export const updateStoreWall = async (id: string, data) => {
  const response = await http.post(`/api/store/${id}/wall`, {wall: data});
  return response.data;
};

export const updateCompanyWall = async (id: string, data) => {
  const response = await http.post(`/api/company/${id}/wall`, {wall: data});
  return response.data;
};

export const updateReservationService = async (id: string, data: number) => {
  const response = await http.post(`/api/store/${id}/reservation`, {
    reservation: data,
  });
  return response.data;
};
export const updateDirectAccessService = async (id: string, data: number) => {
  const response = await http.post(`/api/store/${id}/direct-access`, {
    directAccess: data,
  });
  return response.data;
};

export const fetchRoomsByTopic = async (
  store: string,
  topic?: string,
  flow?: 'live' | 'appointment'
): Promise<Room[]> => {
  const response = await http.get(
    `/api/config/store/${store}/rooms?topic=${topic}&flow=${flow}`
  );
  return response.data;
};

export const fetchSlots = async (
  store: string,
  room?: string,
  topic?: string
): Promise<TimeSlots> => {
  const response = await http.get(
    `/api/config/store/${store}/slots?topic=${topic || ''}&room=${room || ''}`
  );
  return response.data;
};
export const fetchRoomSlots = async (id: string): Promise<SlotsSettings> => {
  const response = await http.get(`/api/room/${id}/slots`);
  return response.data;
};
export const updateRoomSlots = async (
  id: string,
  data: Slots,
  duration: number
) => {
  const response = await http.post(`/api/room/${id}/slots`, {
    reservationSlots: data,
    slotDuration: duration,
  });
  return response.data;
};

export const updateReservation = async (
  id: string,
  data: ReservationRequestDetails
) => {
  const response = await http.post(`/api/bo/reservations/${id}`, data);
  return response.data;
};

export const sendReservation = async (
  data: ReservationRequest
): Promise<Reservation> => {
  const response = await http.post(`/api/reservations`, data);
  return response.data;
};

export const sendAdminReservation = async (
  data: ReservationRequestMulti
): Promise<Reservation> => {
  const response = await http.post(`/api/bo/reservations`, data);
  return response.data;
};

export const sendJoinRequest = async (
  data: LiveRequest
): Promise<RoomWaitingState> => {
  const response = await http.post(
    `/api/video-room/${data.store}/send-join-request`,
    {
      name: data.customerName,
      room: data.room,
    }
  );
  return response.data;
};

export const sendLiveRequestVerification = async (
  request: string,
  phoneNumber: string
) => {
  const response = await http.post(
    `/api/video-room/join-request/${request}/send-verification-code`,
    {phoneNumber}
  );
  return response.data;
};

export const verifyLiveRequest = async (request: string, code: string) => {
  const response = await http.post(
    `/api/video-room/join-request/${request}/verify-verification-code`,
    {code}
  );
  return response.data;
};

export const sendJoinRequestFromReservation = async (
  participantId: string
): Promise<{id: string}> => {
  const response = await http.post(
    `/api/video-room/reservation/${participantId}/send-join-request`
  );
  return response.data;
};

export const joinWebinarFromReservation = async (
  webinarReservation: string
): Promise<{twilioToken: string}> => {
  const response = await http.get(
    `/api/webinar-reservation/${webinarReservation}/join`
  );
  return response.data;
};

export const joinWebinar = async (
  webinar: string
): Promise<{twilioToken: string}> => {
  const response = await http.get(`/api/webinar/${webinar}/join`);
  return response.data;
};

export const joinRoomFromRequest = async (
  request: string
): Promise<{twilioToken: string}> => {
  const response = await http.post(`/api/video-room/join-request/${request}`);
  return response.data;
};

export const approveJoinRequest = async (
  request: string
): Promise<{twilioToken: string}> => {
  const response = await http.post(
    `/api/video-room/join-request/${request}/approve`
  );
  return response.data;
};

export const rejectJoinRequest = async (
  request: string,
  message?: string
): Promise<{twilioToken: string}> => {
  const response = await http.post(
    `/api/video-room/join-request/${request}/reject`,
    {message}
  );
  return response.data;
};

export const busyJoinRequest = async (
  request: string,
  message?: string
): Promise<{twilioToken: string}> => {
  const response = await http.post(
    `/api/video-room/join-request/${request}/busy`,
    {message}
  );
  return response.data;
};

export const joinRoom = async (
  room: string
): Promise<{twilioToken: string}> => {
  const response = await http.post(`/api/video-room/${room}/join`);
  return response.data;
};

export const kickParticipant = async (
  room: string,
  participant: string
): Promise<void> => {
  await http.post(`/api/video-room/${room}/kick/${participant}`);
  // return response.data;
};

export const toggleLiveRoom = async (room: string): Promise<void> => {
  await http.post(`/api/room/${room}/toggle-live`);
};

export const fetchDocuments = async (room: string): Promise<RoomDocument[]> => {
  const response = await http.get(`/api/room/${room}/documents`);

  return response.data;
};

export const uploadDocument = async (
  room: string,
  data: FormData
): Promise<void> => {
  const response = await http.post(`/api/room/${room}/documents`, data);

  return response.data;
};

export const deleteDocument = async (document: string): Promise<void> => {
  const response = await http.delete(`/api/documents/${document}`);

  return response.data;
};

export const uploadMediaImage = async (image: File): Promise<void> => {
  const fd = new FormData();
  fd.append('image', image);

  const response = await http.post(`/api/editor/upload-image`, fd);

  return response.data.url;
};

// LANG
export const updateText = async (
  lang: string,
  room: string,
  key: string,
  label: string
): Promise<void> => {
  await http.post(`/api/room/${room}/translations/${lang}`, {
    key,
    label,
  });
};

export const fetchRoomTranslations = async (room: string): Promise<unknown> => {
  const response = await http.get(`/api/room/${room}/translations`);
  return response.data;
};

// POS
export const requestPosActivation = async (): Promise<void> => {
  await http.get(`/api/pos/request`);
};

export const createPosAccount = async (): Promise<{url: string}> => {
  const response = await http.get(`/api/pos/create`);
  return response.data;
};

export const verifyPosAccount = async (): Promise<{
  status: 'inactive' | 'pending' | 'configured';
}> => {
  const response = await http.get(`/api/pos/verify/`);
  return response.data;
};

export const fetchPayments = async (): Promise<PosPayment[]> => {
  const response = await http.get('/api/pos/payment');
  return response.data;
};

export const createPosPayment = async (
  config: Partial<PosPaymentRequest>
): Promise<{id: string}> => {
  const response = await http.post('/api/pos/create-payment', config);

  return response.data;
};

export const fetchPayment = async (id: string): Promise<PosPayment> => {
  const response = await http.get(`/api/pos/payment/${id}`);
  return response.data;
};

export const cancelPosRequest = async (id: string): Promise<void> => {
  await http.get(`/api/pos/payment/${id}/cancel`);
};

export const confirmPosRequest = async (id: string): Promise<void> => {
  await http.get(`/api/pos/payment/${id}/verify`);
};

// SUBSCRIPTION PAYMENTS
export const fetchPaymentsLog = async (): Promise<PaymentLogs> => {
  const response = await http.get('/api/payments/company');
  return response.data;
};
