// my-theme.ts
import {DefaultTheme} from 'styled-components';

const myTheme: DefaultTheme = {
  colors: {
    main: '#F9B233',
    ymYellow: '#fec52d',
    ymGrey: '#161615',
    ymGreyMedium: '#737373',
    ymGreyLight: '#ECECEC',
    ymGreyLighter: '#FAFAFA',
    ymGreen: '#A0D684',

    // Status
    success: '#76D06E',
    error: '#DB6161',
    warning: '#F9B233',
    loading: '#61A3DB',
    info: '#2997ff',

    // Controls
    decline: '#DD233C',

    // Typography
    bodyText: '#161615',
    buttonText: '#161615',
    heading: '#161615',
    label: '#878787',

    // Elements
    neutral: '#C4C4C4',
    border: '#D3D3D3',
    background: '#FAFAFA',
    shadow: 'rgba(0, 0, 0, 0.05)',
    shadowMobile: 'rgba(0, 0, 0, 0.09)',
  },
  typography: {
    main: "'Outfit', sans-serif",
  },
  layout: {
    adminSpace: '4%',
    frontendSpace: '16px',
    roomHeader: '55px',
    roomFooter: '60px',
    simplePagesHeader: '60px',
  },
  responsive: {
    phoneSmall: `(max-width: 320px)`,
    phone: `(max-width: 640px)`,
    tablet: `(max-width: 768px)`,
    laptop: `(max-width: 1024px)`,
    desktopSmall: `(max-width: 1100px)`,
    desktopSmallHeight: `(max-height: 800px) and (min-width: 1100px)`,
    desktop: `(max-width: 1440px)`,
    desktopLarge: `(min-width: 1920px)`,
  },
  timing: {
    default: '0.3s',
  },
};

export default myTheme;
