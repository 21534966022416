import React, {useState, useCallback, forwardRef} from 'react';
import styled, {css} from 'styled-components';
import {spin} from '../Animations';
import {lighten} from 'polished';
import {IconInfo} from '../Icons/IconInfo';
import Tippy from '@tippyjs/react';

type ButtonLiveProps = {
  isLive: boolean;
  onClick: () => void;
  showHelp?: boolean;
};

const ButtonLive = forwardRef<HTMLButtonElement, ButtonLiveProps>(
  ({isLive, onClick, showHelp = false}, ref) => {
    const [isLiveLoading, setLiveLoading] = useState(false);

    const handleClick = useCallback(async () => {
      setLiveLoading(true);
      await onClick();
      setLiveLoading(false);
    }, [onClick]);

    if (showHelp) {
      return (
        <WithHelpContainer>
          <Tippy
            content={
              <span>
                Attivando quest’opzione, gli utenti potranno{' '}
                <strong>richiedere l’accesso</strong>, riceverai una{' '}
                <strong>notifica</strong> e sarete messi in{' '}
                <strong>connessione subito</strong>
              </span>
            }
          >
            <Help>
              <IconInfo />
            </Help>
          </Tippy>
          <Container
            onClick={() => handleClick()}
            $isLive={isLive}
            $loading={isLiveLoading}
            ref={ref}
            noBorder
          >
            <LiveText isLive={isLive}>LIVE</LiveText>
            <LiveStatus $isLive={isLive}></LiveStatus>
          </Container>
        </WithHelpContainer>
      );
    }

    return (
      <Container
        onClick={() => handleClick()}
        $isLive={isLive}
        $loading={isLiveLoading}
        ref={ref}
      >
        <LiveText isLive={isLive}>LIVE</LiveText>
        <LiveStatus $isLive={isLive}></LiveStatus>
      </Container>
    );
  }
);

export default ButtonLive;

const loadingSize = 16;
const loadingBorder = 2;

const Container = styled.button<{
  $isLive: boolean;
  $loading: boolean;
  noBorder?: boolean;
}>`
  position: relative;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
  padding: 0 12px;
  margin-right: 24px;
  border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  @media ${(props) => props.theme.responsive.tablet} {
    margin-right: 12px;
    padding: 0 6px;
    height: 38px;
  }

  &:hover {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
  }

  ${(props) =>
    props.$loading &&
    css`
      &:after {
        content: '';
        z-index: 2;
        display: block;
        width: ${loadingSize}px;
        height: ${loadingSize}px;
        border: solid ${loadingBorder}px ${(props) => props.theme.colors.main};
        border-bottom-color: white;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        left: calc(50% - ${loadingSize / 2 + loadingBorder}px);
        animation: ${spin} linear infinite 0.5s;
      }
      &:before {
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        border-radius: 25px;
      }
    `}

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
      margin-right: 0;

      @media ${(props) => props.theme.responsive.tablet} {
        margin-right: 0;
      }
    `}
`;

const LiveText = styled.p<{isLive?: boolean}>`
  margin: 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.decline};
  font-family: ${(props) => props.theme.typography.main};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;

  ${(props) =>
    props.isLive &&
    css`
      color: ${props.theme.colors.success};
    `}

  @media ${(props) => props.theme.responsive.tablet} {
    padding: 0 4px;
  }
`;

const LiveStatus = styled.div<{$isLive: boolean}>`
  display: block;
  background-color: ${(props) => props.theme.colors.decline};
  border-radius: 40px;
  width: 38px;
  height: 20px;
  margin-left: 12px;
  position: relative;
  @media ${(props) => props.theme.responsive.phone} {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #fff;
    top: 2px;
    left: 2px;
    position: absolute;
    transition: left 300ms;
  }

  ${(props) =>
    props.$isLive &&
    css`
      background-color: ${(props) => props.theme.colors.success};
      &:before {
        left: 20px;
      }
    `}
`;

const Help = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 25px;

  svg {
    [stroke] {
      stroke: ${(props) => props.theme.colors.label};
    }
  }
`;

const WithHelpContainer = styled.div`
  border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  background-color: ${(props) => props.theme.colors.ymGreyLighter};
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media ${(props) => props.theme.responsive.tablet} {
    margin-right: 12px;
  }
`;
