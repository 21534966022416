import {getContrast} from 'polished';
import myTheme from '../config/theme';

const IDEAL_CONTRAST = 4.5;

export const getContrastedColor = (colorToCheck: string) => {
  const contrastRatioLight = getContrast(colorToCheck, '#fff');
  const contrastRatioDark = getContrast(colorToCheck, myTheme.colors.ymGrey);

  let buttonTextColor: string = '#fff';
  if (
    contrastRatioLight < IDEAL_CONTRAST &&
    contrastRatioDark > contrastRatioLight
  ) {
    buttonTextColor = myTheme.colors.ymGrey;
  }

  return buttonTextColor;
};
