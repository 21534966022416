import React, {useRef} from 'react';
import type {AppContext} from 'next/app';
import {useRouter} from 'next/router';
import Error from './_error.page';
import companyMiddleware from '../utils/companyMiddleware';
import localeMiddleware from '../utils/localeMiddleware';
import {UserProvider} from '../contexts/UserContext';
import {ThemeProvider} from '../contexts/ThemeContext';
import {CompanyProvider} from '../contexts/CompanyContext';
import CookieBanner from '../components/CookieBanner';
import 'react-widgets/scss/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'slick-carousel/slick/slick.css';
import 'tippy.js/dist/tippy.css';
import i18n from '../locale/i18n';

import GlobalStyle from '../config/globalStyles';
import {useSSR} from 'react-i18next';
import {Toaster} from 'react-hot-toast';
import {LocalTracksProvider} from 'youmain-videoroom-lib';
import {SubscriptionProvider} from '../contexts/SubscriptionContext';
import Analytics from '../components/Analytics';

export default function MyApp({
  Component,
  pageProps,
  company,
  translations,
}: AppPropsCustom) {
  const initialCompany = useRef(company);

  const router = useRouter();

  const Layout = Component.layout || React.Fragment;

  const LocalTracks = Component.useLocalTracks
    ? LocalTracksProvider
    : React.Fragment;

  const Subscription = router.pathname.includes('admin')
    ? SubscriptionProvider
    : React.Fragment;

  const {errorCode} = pageProps;
  const Comp = errorCode ? (
    <Error statusCode={errorCode} {...pageProps} />
  ) : (
    <Component {...pageProps} />
  );

  useSSR(translations, 'it');

  return (
    <>
      <UserProvider>
        <CompanyProvider value={initialCompany.current}>
          <LocalTracks>
            <Subscription>
              <ThemeProvider>
                <Layout>
                  <GlobalStyle />
                  {Comp}
                  <CookieBanner />
                  <Toaster position="top-right" gutter={16} />
                  <Analytics />
                </Layout>
              </ThemeProvider>
            </Subscription>
          </LocalTracks>
        </CompanyProvider>
      </UserProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  let pageProps = {};

  try {
    await companyMiddleware(appContext);

    // TODO: Necessita refactoring - fa la chiamata troppo spesso
    await localeMiddleware(appContext);

    if (appContext.Component.getInitialProps) {
      pageProps = await appContext.Component.getInitialProps(appContext.ctx);
    }
  } catch (e) {
    // if res is finished
    // some middleware has res.end();
    if (appContext.ctx?.res?.finished) {
      return;
    }

    throw e;
  }

  // this needs for ssr
  if (appContext.ctx.translations) {
    for (let lang in appContext.ctx.translations) {
      for (let ns in appContext.ctx.translations[lang]) {
        i18n.addResources(lang, ns, appContext.ctx.translations[lang][ns]);
      }
    }
  }

  return {
    pageProps,
    translations: appContext.ctx.translations,
    company: appContext.ctx.currentCompany,
  };
};
