import type {AppContext} from 'next/app';
import * as API from './api';

export default async function companyMiddleware(
  appContext: AppContext
): Promise<void> {
  const {req, res} = appContext.ctx;

  if (!req || !req.headers.host) {
    return;
  }

  try {
    const company = await API.fetchCompanyConfig(req.headers.host);
    appContext.ctx.currentCompany = company;
  } catch (e) {
    if (e?.response?.status === 404) {
      res.writeHead(404);
      res.end();

      throw new Error('Company not found');
    }
    throw e;
  }
}
