import styled from 'styled-components';

export const Main = styled.main`
  display: block;
  flex: 1 1 80%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  @media ${(props) => props.theme.responsive.phone} {
    flex: 1 1 auto;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: block;
  margin-bottom: calc(${(props) => props.theme.layout.frontendSpace} * 2);
`;

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(${(props) => props.theme.layout.frontendSpace} * 2);
`;

export const Block = styled.div`
  width: 100%;
  margin: 45px 0 20px;
  padding-bottom: 25px;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  @media ${(props) => props.theme.responsive.phone} {
    flex-wrap: wrap;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const Legend = styled.div<{top?: string}>`
  display: block;
  flex: 0 0 19%;
  margin-right: calc(${(props) => props.theme.layout.adminSpace});
  align-self: flex-start;
  position: sticky;
  top: ${(props) => (props.top ? props.top : '150px')};
  @media ${(props) => props.theme.responsive.phone} {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.layout.frontendSpace};
    position: relative;
    top: inherit;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  @media ${(props) => props.theme.responsive.phone} {
    flex: 0 0 100%;
    margin-right: 0;
  }

  form {
    width: 100%;
  }
`;

export const Divider = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.border};
  border: none;
  margin: ${(props) => props.theme.layout.frontendSpace} 0
    calc(${(props) => props.theme.layout.frontendSpace} * 2);
`;
