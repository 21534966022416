import Image from 'next/image';
import React from 'react';
import styled, {css} from 'styled-components';
import defaultLogo from '../../../../assets/images/logo-default.svg';
import {useCompany} from '../../../../contexts/CompanyContext';

interface LogoProps {
  full?: boolean;
  center?: boolean;
  adminSidebar?: boolean;
}

export const Logo = ({full, center, adminSidebar}: LogoProps) => {
  const [company] = useCompany();

  const logo = company?.logo || defaultLogo.src;

  return (
    <LogoContainer full={full} center={center} adminSidebar={adminSidebar}>
      <Image
        src={logo}
        alt="Logo"
        style={{
          objectFit: 'contain',
          objectPosition: center ? 'center' : 'left center',
        }}
        fill
      />
    </LogoContainer>
  );
};

const LogoContainer = styled.div<{
  full?: boolean;
  center?: boolean;
  adminSidebar?: boolean;
}>`
  position: relative;
  display: inline-block;
  background-color: transparent;
  width: 50%;
  height: 48px;
  margin: 12px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;

  ${(props) =>
    props.full &&
    css`
      width: 100%;
      height: auto;
      padding: 30px 0;
    `}
  ${(props) =>
    props.center &&
    css`
      background-position: center;
      margin: 12px 0;
    `}

    ${(props) =>
    props.adminSidebar &&
    css`
      padding: 0
      margin: 12px 18px;
      width: calc(100% - 24px);
    `}
`;
