import React, {forwardRef, PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';

type ButtonControlProps = PropsWithChildren<{
  invert: boolean;
  icon?: (props: IconProps) => JSX.Element;
  iconColor?: string;
  onClick?: () => void;
  transparent?: boolean;
  label?: string;
  toggle?: boolean;
  count?: number;
  mobileLabel?: string;
}>;

const ButtonControl = forwardRef<HTMLButtonElement, ButtonControlProps>(
  (
    {
      invert,
      icon,
      iconColor,
      onClick,
      transparent,
      label,
      toggle,
      count,
      mobileLabel,
      children,
    },
    ref
  ) => {
    const IconComp = icon || React.Fragment;
    let iconFill = iconColor || '#fff';

    if (invert) {
      iconFill = 'white';
    }

    return (
      <Container
        $label={label}
        $invert={invert}
        $transparent={transparent}
        $color={iconFill}
        $toggle={toggle}
        onClick={onClick}
        ref={ref}
        data-label={mobileLabel && mobileLabel}
      >
        {!!count && count > 0 && <Count>{count}</Count>}
        <IconComp fill={iconFill} stroke="2px" />
        {label && <span>{label}</span>}
        {children}
      </Container>
    );
  }
);

export default ButtonControl;

const Container = styled.button<{
  $invert: boolean;
  $label?: string;
  $transparent?: boolean;
  $color: string;
  $toggle?: boolean;
}>`
  position: relative;
  width: ${(props) => (props.$label ? 'auto' : '40px')};
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 40px;
  padding: 0 4px;
  border: 1px solid transparent;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  backdrop-filter: blur(10px);
  transition: all ease 0.2s;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};
  @media ${(props) => props.theme.responsive.phone} {
    width: 38px;
    height: 38px;
    padding: 8px;
  }

  span {
    color: ${(props) => props.$color};
    font-family: ${(props) => props.theme.typography.main};
    font-size: 15px;
    margin-left: 5px;
    @media ${(props) => props.theme.responsive.phone} {
      display: none;
    }
  }

  & + button {
    margin-left: 8px;
  }

  &:hover {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.5);
  }

  ${(props) =>
    props.$invert &&
    css`
      background-color: ${props.theme.colors.decline};
      border: 1px solid ${darken(0.2, props.theme.colors.decline)};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.decline)};
      }

      ${Count} {
        background-color: #fff;
        color: ${props.theme.colors.decline};
      }
    `}

  ${(props) =>
    props.$invert &&
    props.$toggle &&
    css`
      background-color: ${props.theme.colors.success};
      border: 1px solid ${darken(0.2, props.theme.colors.success)};

      &:hover {
        background-color: ${darken(0.1, props.theme.colors.success)};
      }

      ${Count} {
        background-color: #fff;
        color: ${props.theme.colors.success};
      }
    `}

  ${(props) =>
    props.$transparent &&
    css`
      background-color: transparent;
      backdrop-filter: none;
    `}

  ${(props) =>
    props.$label &&
    css`
      padding: 0 12px;
    `}
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.colors.decline};
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
