import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';

type SidebarProps = PropsWithChildren<{
  small?: boolean;
}>;

const Sidebar = ({small, children}: SidebarProps) => {
  return <SidebarContainer small={small}>{children}</SidebarContainer>;
};

export default Sidebar;

const SidebarContainer = styled.aside<{small?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 16%;
  width: 16%;
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.ymGreyLight};
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 500ms, flex 500ms, transform 500ms, padding 500ms;

  @media ${(props) => props.theme.responsive.laptop} {
    width: 58px;
    flex: 0 0 58px;
  }

  ${(props) =>
    props.small &&
    css`
      width: 58px;
      flex: 0 0 58px;
      @media ${(props) => props.theme.responsive.laptop} {
        width: 0;
        flex: 0 0 0;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
      }
    `}
`;
