import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useCallback,
} from 'react';
import {fetchCurrentUser} from '../utils/api';
import useSWR from 'swr';

interface UserContextType {
  user: UserOrGuest | null;
  updateUser: () => Promise<void>;
  loading: boolean;
  isGuest: boolean;
}

export const UserContext = createContext<UserContextType>({
  user: null,
  updateUser: async () => {},
  loading: true,
  isGuest: true,
});

export const UserProvider = ({children}: PropsWithChildren<{}>) => {
  const {
    data: currentUser,
    isValidating,
    mutate,
  } = useSWR('/api/me', () => fetchCurrentUser(), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  const updateUser = useCallback(
    () => mutate().then(() => undefined),
    [mutate]
  );

  const isGuest = !!currentUser?.isGuest;

  return (
    <UserContext.Provider
      value={{
        user: currentUser || null,
        updateUser: updateUser,
        loading: isValidating,
        isGuest,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser: () => UserContextType = () => useContext(UserContext);

export const useRequiredUser = () => {
  const {user, ...rest} = useUser();

  if (user === null) {
    throw new Error('Required user');
  }

  return {user, ...rest};
};
