import styled, {css} from 'styled-components';

export const HeadingL = styled.h1<{light?: boolean; colored?: boolean}>`
  margin: 0 0 16px;
  font-family: ${(props) => props.theme.typography.main};
  font-size: 40px;
  line-height: 60px;
  color: ${(props) => props.theme.colors.ymGrey};

  ${(props) => props.light && `font-weight: 400;`}

  ${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.colors.main};
    `}

  @media ${(props) => props.theme.responsive.phone} {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 10px;
  }
`;

export const HeadingM = styled.h2<{light?: boolean; colored?: boolean}>`
  margin: 0 0 16px;
  font-family: ${(props) => props.theme.typography.main};
  font-size: 30px;
  line-height: 44px;
  color: ${(props) => props.theme.colors.ymGrey};

  @media ${(props) => props.theme.responsive.phone} {
    line-height: 1.2;
    font-size: 23px;
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}
  ${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.colors.main};
    `}
`;

export const HeadingS = styled.h3<{light?: boolean; colored?: boolean}>`
  margin: 0 0 16px;
  font-family: ${(props) => props.theme.typography.main};
  font-size: 25px;
  line-height: 24px;
  font-weight: 500;

  @media ${(props) => props.theme.responsive.phone} {
    font-size: 20px;
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}
  ${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.colors.main};
    `}
`;

export const HeadingXS = styled.h3<{
  light?: boolean;
  colored?: boolean;
  $color?: string;
}>`
  margin: 0 0 16px;
  font-family: ${(props) => props.theme.typography.main};
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.ymGrey};

  @media ${(props) => props.theme.responsive.phone} {
    margin-bottom: 10px;
  }

  ${(props) => props.light && `font-weight: 400;`}
  ${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.colors.main};
    `}
  ${(props) => props.$color && `color: ${props.$color};`}
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.typography.main};
  font-size: 16px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.ymGrey};
  margin: 0 0 16px;
`;

export const TextS = styled.p`
  font-family: ${(props) => props.theme.typography.main};
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.colors.ymGrey};
  margin: 0 0 16px;
`;

export const HelpText = styled(TextS)`
  font-size: 13px;
  color: ${(props) => props.theme.colors.label};

  &:last-of-type {
    margin-bottom: calc(${(props) => props.theme.layout.frontendSpace} * 2);
  }
`;

export const Subtitle = styled(Text)`
  margin-bottom: 30px;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-family: ${(props) => props.theme.typography.main};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.ymGreyMedium};

  span {
    color: ${(props) => props.theme.colors.error};
  }
`;

export const Nbsp = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export const Discount = styled.div`
  background-color: ${(props) => props.theme.colors.success};
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 3px 2px;
  text-transform: uppercase;
  display: inline-block;
`;

export const Remove = styled.div`
  color: ${(props) => props.theme.colors.error};
  display: inline;
  font-size: 14px;
  margin-left: ${(props) => props.theme.layout.frontendSpace};
  cursor: pointer;
`;

export const Required = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

export const OrderedList = styled.ol`
  font-family: ${(props) => props.theme.typography.main};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.bodyText};
  margin: 0 0 16px;

  li {
    margin-bottom: 12px;
  }
`;
