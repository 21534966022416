import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useRouter} from 'next/router';
import Head from 'next/head';
import {Logo} from '../components/Admin/Layout';
import {Icon404} from '../components/Icons';
import {useCompany} from '../contexts/CompanyContext';
import {HeadingM, TextS} from '../components/Typography';
import {Button} from '../components/Buttons';

interface Custom404Props {
  statusCode: any;
  error?: any;
}

export default function Custom404({statusCode, error}: Custom404Props) {
  const theme = useContext(ThemeContext);
  const [company] = useCompany();
  const router = useRouter();

  const goToHome = () => {
    router.replace('/');
  };

  return (
    <>
      <Head>
        <title>{company?.name || 'Youmain'} - 404 Pagina non trovata</title>
        <meta
          property="og:title"
          content={`${company?.name || 'Youmain'} - 404 Pagina non trovata`}
          key="title"
        />
      </Head>
      <Header>
        <Logo />
      </Header>
      <Page>
        <ErrorMessage>
          <ErrorTypeContent>
            <Icon404 fill={theme.colors.warning} size="100px" />
          </ErrorTypeContent>
          <ErrorMessageContent>
            <HeadingM>La pagina non è stata trovata</HeadingM>
            <TextS>
              La pagina ricercata non può essere raggiunta oppure non esite.
            </TextS>
            <Button
              title="Torna alla home"
              onClick={() => goToHome()}
              primary
              small
            />
          </ErrorMessageContent>
        </ErrorMessage>
      </Page>
    </>
  );
}

const Header = styled.header`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background-color: #fff;
  padding: 0 15%;
  height: ${(props) => props.theme.layout.simplePagesHeader};
  box-shadow: 0px 0px 12px ${(props) => props.theme.colors.shadow};

  @media ${(props) => props.theme.responsive.tablet} {
    padding: 0 5%;
  }

  > div {
    height: 60%;
    padding: 0;
    margin: 0;
  }
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${(props) => props.theme.layout.simplePagesHeader});
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => props.theme.layout.simplePagesHeader};
`;

const ErrorMessage = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media ${(props) => props.theme.responsive.phone} {
    width: 90%;
  }
`;

const ErrorTypeContent = styled.div`
  display: block;
  text-align: center;
  @media ${(props) => props.theme.responsive.phone} {
    flex: 0 0 100%;
  }
`;

const ErrorMessageContent = styled.div`
  display: block;
  text-align: left;
  padding-left: 36px;
  margin-left: 36px;
  border-left: 1px solid ${(props) => props.theme.colors.border};
  @media ${(props) => props.theme.responsive.phone} {
    flex: 0 0 100%;
    text-align: center;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    margin-top: 36px;
  }

  ${HeadingM} {
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.warning};
    @media ${(props) => props.theme.responsive.phone} {
      margin-bottom: 12px;
    }
  }
`;
